import axios from 'axios';

const downloadVideo = async (meeting) => {
  try {
    await axios
      .get(`/meetings/${meeting.id}/download`, { responseType: 'blob' })
      .then((response) => {
        const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', `${meeting.title}.mp4`);
        document.body.appendChild(link);
        link.click();
      });
  } catch (error) {
    console.log(error);
  }
};

export default downloadVideo;
