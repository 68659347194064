/* eslint-disable no-unused-vars */

/**
 * Adjusts the position of a tooltip according to a zoom factor defined as a CSS variable.
 *
 * This function reads the `--zoom` CSS variable from the root document element. If the zoom
 * is not `1`, it attempts to locate a visible tooltip element (with the class `.tooltip.show`)
 * and modify its `translate3d()` values so that the tooltip remains correctly positioned
 * under the current zoom scale.
 *
 * It uses `requestAnimationFrame` to repeatedly check for the tooltip's transform values.
 * Once the tooltip's `translate3d()` transform is found, those values are divided by the zoom
 * factor and applied back to the tooltip.
 *
 * If no tooltip is found or the zoom is `1`, the function returns immediately without making changes.
 *
 * @example
 * // Assuming the root element has a CSS variable:
 * // :root { --zoom: 0.8; }
 * // and a tooltip is rendered with .tooltip.show,
 * // calling adjustTooltipOnZoom() will scale down the tooltip's translate3d values accordingly.
 *
 * adjustTooltipOnZoom();
 */

export default function adjustTooltipOnZoom() {
  const zoom = getComputedStyle(document.documentElement)
    .getPropertyValue('--zoom')
    .trim();

  if (zoom === '1') {
    return;
  }

  function checkTransformAndApplyChanges() {
    const tooltip = document.querySelector('.tooltip.show');

    if (!tooltip) {
      return;
    }

    tooltip.style.visibility = 'visible';

    const currentTransform = tooltip.style.transform;

    const match = currentTransform.match(
      /translate3d\(\s*([-0-9.]+)px,\s*([-0-9.]+)px,\s*([-0-9.]+)px\)/
    );

    if (match) {
      let x = parseFloat(match[1]);
      let y = parseFloat(match[2]);
      let z = parseFloat(match[3]);

      x /= zoom;
      y /= zoom;
      z /= zoom;

      tooltip.style.transform = `translate3d(${x}px, ${y}px, ${z}px)`;
    } else {
      requestAnimationFrame(checkTransformAndApplyChanges);
    }
  }

  requestAnimationFrame(checkTransformAndApplyChanges);
}
