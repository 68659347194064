import React from 'react';

import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import closeIcon from '../../assets/images/closeIcon.svg';

import FilledButton from '../../components/Buttons/FilledButton';
import classes from './styles.module.scss';
import adjustTooltipOnZoom from '../../helpers/adjustTooltipOnZoom';

export default function SelectShareOptionModal({
  show,
  handleClose,
  openRequestReviewModal,
  openShareMeetingModal,
}) {
  return (
    <Modal
      show={show}
      centered
      contentClassName={classes.SelectShareOptionModal}
      dialogClassName={classes.dialog}
      backdropClassName={classes.backdrop}
    >
      <i className={classes.closeIcon} onClick={handleClose}>
        <img src={closeIcon} alt="Close" />
      </i>
      <div className={classes.content}>
        <h3>What do you want to do?</h3>
        <div className={classes.buttons}>
          <OverlayTrigger
            onToggle={adjustTooltipOnZoom}
            placement="top-start"
            overlay={
              <Tooltip>
                Share your SOP video with others to review and approve content
              </Tooltip>
            }
          >
            <div>
              <FilledButton
                onClick={openRequestReviewModal}
                width={270}
                style={{ whiteSpace: 'noWrap' }}
              >
                Send for Review
              </FilledButton>
            </div>
          </OverlayTrigger>
          <OverlayTrigger
            onToggle={adjustTooltipOnZoom}
            placement="top-start"
            overlay={<Tooltip>Share your final SOP video with others</Tooltip>}
          >
            <div>
              <FilledButton
                onClick={openShareMeetingModal}
                width={270}
                style={{ whiteSpace: 'noWrap' }}
                color="#111"
              >
                Share my video
              </FilledButton>
            </div>
          </OverlayTrigger>
        </div>
      </div>
    </Modal>
  );
}
