import React from 'react';
import Modal from 'react-bootstrap/Modal';

import closeIcon from '../../assets/images/closeIcon.svg';

import FilledButton from '../../components/Buttons/FilledButton';
import classes from './styles.module.scss';

export default function ImportFileModal({
  show,
  handleClose,
  onMobileUploadsClick,
  onSavedSectionsClick,
  onMyComputerClick,
}) {
  const buttons = [
    {
      title: 'Mobile Uploads',
      handleClick: onMobileUploadsClick,
      color: '',
    },
    {
      title: 'Saved Sections',
      handleClick: onSavedSectionsClick,
      color: 'black',
    },
    {
      title: 'My Computer',
      handleClick: onMyComputerClick,
      color: '#FDB258',
    },
  ];

  return (
    <Modal
      show={show}
      centered
      contentClassName={classes.ImportFileModal}
      dialogClassName={classes.dialog}
      backdropClassName={classes.backdrop}
    >
      <div onClick={(event) => event.stopPropagation()}>
        <i className={classes.closeIcon} onClick={handleClose}>
          <img src={closeIcon} alt="Close" />
        </i>
        <div className={classes.content}>
          <h2 className={classes.title}>Where are you importing from?</h2>

          <div className={classes.buttonsContainer}>
            {buttons.map((button) => (
              <FilledButton
                onClick={(event) => {
                  event.stopPropagation();
                  button.handleClick();
                  handleClose();
                }}
                key={button.title}
                className={classes.button}
                color={button.color}
                width={263}
              >
                {button.title}
              </FilledButton>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
}
