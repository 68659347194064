import React from 'react';

import { useNavigate } from 'react-router-dom';

import classes from './styles.module.scss';
import backIcon from '../../../assets/images/video-player/back.svg';
import shareIcon from '../../../assets/images/video-player/share.svg';

export default function Header({
  meetingTitle,
  setIsShareMeetingModalVisible,
  isPublishing,
  viewOnly,
  onBackClick,
}) {
  const navigate = useNavigate();

  return (
    <div
      className={classes.Header}
      style={{
        visibility: isPublishing ? 'hidden' : 'visible',
      }}
    >
      <div className={classes.buttonContainer}>
        <button
          type="button"
          onClick={(event) => {
            event.stopPropagation();

            if (onBackClick) {
              onBackClick();
            } else {
              navigate(-1);
            }
          }}
        >
          <img src={backIcon} alt="Back" />
        </button>
        <h2 className={classes.title}>{meetingTitle}</h2>
      </div>
      <button
        style={{
          visibility: viewOnly ? 'hidden' : 'visible',
        }}
        className={classes.shareButton}
        type="button"
        onClick={(event) => {
          setIsShareMeetingModalVisible(true);
          event.stopPropagation();
        }}
      >
        <img src={shareIcon} alt="Share" />
        Share Video
      </button>
    </div>
  );
}
