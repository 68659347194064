/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef, useContext } from 'react';

import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

// import InfoIcon from '../../InfoIcon';
import cameraIcon from '../../../assets/images/section-menu/camera.svg';
import selfIcon from '../../../assets/images/section-menu/self.svg';
import desktopIcon from '../../../assets/images/section-menu/desktop.svg';
import questionIcon from '../../../assets/images/section-menu/question.svg';
import assignIcon from '../../../assets/images/section-menu/assign.svg';
import audioIcon from '../../../assets/images/section-menu/audio.svg';
import notesIcon from '../../../assets/images/section-menu/notes.svg';
import importIcon from '../../../assets/images/section-menu/import.svg';

import { UiContext } from '../../../context/UiContext';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';
import ImportFileModal from '../../../modals/ImportFileModal';
import adjustTooltipOnZoom from '../../../helpers/adjustTooltipOnZoom';

export default function SectionMenu({
  onCamera,
  onScreen,
  isScreenEnabled,
  isCameraEnabled,
  isRecording,
  showPlaceholder,
  onQuestion,
  setIsQuestionFormVisible,
  isQuestionFormVisible,
  onAssignSection,
  isAssignSectionFormVisible,
  meeting,
  meetingTitle,
  setIsNoTitleWarningVisible,
  isAudioEnabled,
  onAudio,
  sections,
  createMeeting,
  folderId,
  setMeetingId,
  refetchSections,
  setFile,
  setSectionTitle,
  createDefaultSectionName,
  createSection,
  updateTextSection,
  prevSectionId,
  hidePlaceholder,
}) {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [leftPosition, setLeftPosition] = useState(0);
  const [bottomPosition, setBottomPosition] = useState(0);
  const [isRecordSubmenuVisible, setIsRecordSubmenuVisible] = useState(false);
  const [isImportFileModalVisible, setIsImportFileModalVisible] =
    useState(false);
  const [isEnoughSpaceForSubmenu, setIsEnoughSpaceForSubmenu] = useState(true);

  const menuRef = useRef();
  const toggleRef = useRef();
  const fileInputRef = useRef();
  const plusSignRef = useRef();

  const navigate = useNavigate();

  useOnClickOutside(menuRef, () => setIsMenuVisible(false), toggleRef);

  const { showMeetingNotesModal } = useContext(UiContext);

  useEffect(() => {
    if (!plusSignRef.current) {
      return;
    }

    const zoom = getComputedStyle(document.documentElement)
      .getPropertyValue('--zoom')
      .trim();

    const triggerCoords = plusSignRef.current.getBoundingClientRect();

    setLeftPosition(triggerCoords.left / zoom);
    setBottomPosition(window.innerHeight / zoom - triggerCoords.bottom / zoom);
  }, [isMenuVisible]);

  // On menu render check if there is enough space for submenu
  useEffect(() => {
    if (!menuRef.current) {
      return;
    }

    const menuCoords = menuRef.current.getBoundingClientRect();

    if (menuCoords.left < 242) {
      setIsEnoughSpaceForSubmenu(false);
    } else {
      setIsEnoughSpaceForSubmenu(true);
    }
  }, [isMenuVisible, leftPosition]);

  const lastSection = sections?.[sections.length - 1];

  const isMenuBlocked =
    isCameraEnabled ||
    isRecording ||
    isScreenEnabled ||
    isAudioEnabled ||
    (isQuestionFormVisible && lastSection?.placeholderType === 'question') ||
    isAssignSectionFormVisible ||
    meeting?.userRole === 'contributor-closed';

  const isAssignSectionButtonDisabled = meeting?.userRole === 'contributor';

  const showRecordSubmenu = () => {
    setIsRecordSubmenuVisible(true);
  };

  const hideRecordSubmenu = () => {
    setIsRecordSubmenuVisible(false);
  };

  const handleMyComputerClick = () => {
    fileInputRef.current.click();

    const sectionTitle = createDefaultSectionName({
      placeholderType: 'video',
    });

    setSectionTitle(sectionTitle);
  };

  return (
    <>
      <div
        ref={toggleRef}
        className={classNames(
          classes.SectionMenu,
          !sections?.length && classes.emptyList
        )}
        onClick={() => setIsMenuVisible((prevState) => !prevState)}
      >
        <div className={classes.toggle}>
          <OverlayTrigger
            onToggle={adjustTooltipOnZoom}
            placement="top-start"
            overlay={
              <Tooltip>
                Choose different ways to record content for your video
              </Tooltip>
            }
          >
            <svg
              ref={plusSignRef}
              width="34"
              height="33"
              viewBox="0 0 34 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.8361 0V12.1291H0.707031V20.2151H12.8361V32.3442H20.9222V20.2151H33.0513V12.1291H20.9222V0H12.8361Z"
                fill="#F8585C"
              />
            </svg>
          </OverlayTrigger>
          <span>Add Section</span>
        </div>

        {isMenuVisible && (
          <div
            className={classes.menu}
            ref={menuRef}
            style={{ left: leftPosition, bottom: bottomPosition }}
            onClick={(event) => event.stopPropagation()}
          >
            <ul>
              <li
                className={classNames({
                  [classes.disabled]: isMenuBlocked,
                })}
                onMouseEnter={showRecordSubmenu}
                onMouseLeave={hideRecordSubmenu}
              >
                <span className={classes.iconContainer}>
                  <img src={cameraIcon} alt="Camera" />
                </span>
                Record options
                {isRecordSubmenuVisible && (
                  <div
                    className={classNames(classes.subMenu, {
                      [classes.right]: !isEnoughSpaceForSubmenu,
                    })}
                    onMouseEnter={showRecordSubmenu}
                    onMouseLeave={hideRecordSubmenu}
                  >
                    <ul>
                      <li
                        className={classNames({
                          [classes.disabled]: isMenuBlocked,
                        })}
                        onClick={() => {
                          if (isMenuBlocked) {
                            return;
                          }

                          if (!meetingTitle.trim()) {
                            setIsNoTitleWarningVisible(true);
                            setIsMenuVisible(false);
                            return;
                          }

                          if (!isCameraEnabled && !isRecording) {
                            onCamera();
                          }

                          if (!isRecording) {
                            showPlaceholder('', 'video', prevSectionId);
                          }

                          setIsMenuVisible(false);
                          setIsQuestionFormVisible(false);
                        }}
                      >
                        <span className={classes.iconContainer}>
                          <img src={selfIcon} alt="Self" />
                        </span>
                        Record self
                      </li>
                      <li
                        className={classNames({
                          [classes.disabled]: isMenuBlocked,
                        })}
                        onClick={() => {
                          if (isMenuBlocked) {
                            return;
                          }

                          if (!meetingTitle.trim()) {
                            setIsNoTitleWarningVisible(true);
                            setIsMenuVisible(false);
                            return;
                          }

                          if (!isScreenEnabled && !isRecording) {
                            onScreen();
                          }
                          if (!isRecording) {
                            showPlaceholder('', 'video', prevSectionId);
                          }
                          setIsMenuVisible(false);
                          setIsQuestionFormVisible(false);
                        }}
                      >
                        <span className={classes.iconContainer}>
                          <img src={desktopIcon} alt="Desktop" />
                        </span>
                        Record screen
                      </li>
                      <li
                        className={classNames({
                          [classes.disabled]: isMenuBlocked,
                        })}
                        onClick={() => {
                          if (isMenuBlocked) {
                            return;
                          }

                          if (!meetingTitle.trim()) {
                            setIsNoTitleWarningVisible(true);
                            setIsMenuVisible(false);
                            return;
                          }

                          if (!isAudioEnabled && !isRecording) {
                            onAudio();
                          }
                          if (!isRecording) {
                            showPlaceholder('', 'audio', prevSectionId);
                          }
                          setIsMenuVisible(false);
                          setIsQuestionFormVisible(false);
                        }}
                      >
                        <span className={classes.iconContainer}>
                          <img
                            src={audioIcon}
                            alt="Audio"
                            style={{ width: 47 }}
                          />
                        </span>
                        Record audio
                      </li>
                    </ul>
                  </div>
                )}
              </li>
              <li
                className={classNames({
                  [classes.disabled]: isMenuBlocked,
                })}
                onClick={() => {
                  if (isMenuBlocked) {
                    return;
                  }

                  if (!meetingTitle.trim()) {
                    setIsNoTitleWarningVisible(true);
                    setIsMenuVisible(false);
                    return;
                  }

                  onQuestion();
                  showPlaceholder('', 'question', prevSectionId);
                  setIsMenuVisible(false);
                }}
              >
                <span className={classes.iconContainer}>
                  <img
                    src={questionIcon}
                    alt="Question"
                    style={{ position: 'relative', left: 8 }}
                  />
                </span>
                <div className={classes.nameContainer}>
                  Add a question
                  {/* <InfoIcon
                  text="Add a question anywhere in your meeting to capture feedback from your viewers"
                  width={320}
                /> */}
                </div>
              </li>
              <li
                className={classNames({
                  [classes.disabled]:
                    isMenuBlocked || isAssignSectionButtonDisabled,
                })}
                onClick={() => {
                  if (isMenuBlocked || isAssignSectionButtonDisabled) {
                    return;
                  }

                  if (!meetingTitle.trim()) {
                    setIsNoTitleWarningVisible(true);
                    setIsMenuVisible(false);
                    return;
                  }

                  onAssignSection();
                  showPlaceholder('', 'assign', prevSectionId);
                  setIsMenuVisible(false);
                }}
              >
                <span className={classes.iconContainer}>
                  <img src={assignIcon} alt="Assign" />
                </span>
                <div className={classes.nameContainer}>
                  Assign a Section
                  {/* <InfoIcon
                  text="Invite others to create content for your video"
                  width={320}
                /> */}
                </div>
              </li>
              <li
                className={classNames({
                  [classes.disabled]: isMenuBlocked,
                })}
                onClick={async () => {
                  if (isMenuBlocked) {
                    return;
                  }

                  if (!meetingTitle.trim()) {
                    setIsNoTitleWarningVisible(true);
                    setIsMenuVisible(false);
                    return;
                  }

                  const noteSectionCount = sections?.filter(
                    (section) => section.type === 'note'
                  ).length;

                  if (!meeting) {
                    const newMeeting = await createMeeting({
                      title: meetingTitle,
                      folderId,
                    });
                    const { id } = newMeeting;
                    setMeetingId(id);

                    navigate(`/my-videos/${id}/edit`, { replaceState: true });
                    showMeetingNotesModal({
                      refetchSections,
                      noteSectionCount,
                      createSection,
                      updateTextSection,
                      hidePlaceholder,
                    });

                    return;
                  }

                  showPlaceholder('', 'note', prevSectionId);
                  showMeetingNotesModal({
                    refetchSections,
                    noteSectionCount,
                    createSection,
                    updateTextSection,
                    hidePlaceholder,
                  });
                }}
              >
                <span className={classes.iconContainer}>
                  <img src={notesIcon} alt="Notes" />
                </span>
                Add Text
              </li>
              <li
                className={classNames({
                  [classes.disabled]: isMenuBlocked,
                })}
                onClick={() => {
                  if (isMenuBlocked) {
                    return;
                  }

                  setIsImportFileModalVisible(true);
                  setIsMenuVisible(false);
                }}
              >
                <span className={classes.iconContainer}>
                  <img
                    src={importIcon}
                    alt="Import file"
                    style={{ position: 'relative', left: -5 }}
                  />
                </span>
                <div className={classes.nameContainer}>
                  Import file
                  {/* <InfoIcon
                  text="Add your own video files. Supported formats: MP4, MOV, WMV, AVI, WebM"
                  width={320}
                /> */}
                </div>
              </li>
            </ul>
          </div>
        )}
        <input
          type="file"
          style={{ display: 'none' }}
          onChange={(event) => {
            showPlaceholder('', 'video', prevSectionId);
            setFile(event.target.files[0]);
          }}
          onClick={(event) => event.stopPropagation()}
          ref={fileInputRef}
          accept="video/mp4, video/quicktime, video/x-ms-wmv, video/x-msvideo, video/webm"
        />
      </div>{' '}
      <ImportFileModal
        show={isImportFileModalVisible}
        handleClose={() => {
          setIsImportFileModalVisible(false);
        }}
        onMobileUploadsClick={() => {
          navigate(
            `/my-videos?tab=mobile uploads&meetingId=${meeting.id}${
              prevSectionId ? `&prevSectionId=${prevSectionId}` : ''
            }`
          );
        }}
        onSavedSectionsClick={() => {
          navigate(
            `/my-videos?tab=saved&meetingId=${meeting.id}${
              prevSectionId ? `&prevSectionId=${prevSectionId}` : ''
            }`
          );
        }}
        onMyComputerClick={handleMyComputerClick}
      />
    </>
  );
}
