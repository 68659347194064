import React from 'react';

import classNames from 'classnames';

import downArrow from '../../assets/images/down-arrow.svg';

export default function Th({
  classes,
  sortField,
  sortOrder,
  setSortField,
  setSortOrder,
  field,
  title,
  noSort,
}) {
  return (
    <th
      className={classNames(classes.sortableTh, {
        [classes.sorted]: sortField === field,
        [classes.sortable]: !!title && !noSort,
      })}
      onClick={() => {
        if (!title || noSort) {
          return;
        }
        setSortField(field);
        setSortOrder((prevState) => {
          return prevState === 'ASC' ? 'DESC' : 'ASC';
        });
      }}
    >
      {title && title.split(' ').slice(0, -1).join(' ')}{' '}
      <span style={{ whiteSpace: 'nowrap' }}>
        {title.split(' ').slice(-1).join(' ')}&nbsp;
        <span
          style={{
            visibility: sortField === field && !noSort ? 'visible' : 'hidden',
          }}
          className={classNames(classes.sortOrder, {
            [classes.asc]: sortOrder === 'ASC',
            [classes.desc]: sortOrder === 'DESC',
          })}
        >
          <img className={classes.chevron} src={downArrow} alt="Sort order" />
        </span>
      </span>
    </th>
  );
}
