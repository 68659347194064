/* eslint-disable react/button-has-type */
import React from 'react';

import cn from 'classnames';

import classes from './styles.module.scss';

export default function FilledButton({
  onClick,
  children,
  type = 'button',
  isDisabled,
  className,
  color,
  width,
  style,
}) {
  return (
    <button
      type={type}
      disabled={isDisabled}
      className={cn(classes.FilledButton, className)}
      onClick={onClick}
      style={{ backgroundColor: color, width, ...style }}
    >
      {children}
    </button>
  );
}
