import React from 'react';

import classes from './styles.module.scss';
import editIcon from '../../../assets/images/my-meetings/edit2.svg';
import deleteIcon from '../../../assets/images/my-meetings/delete.svg';

export default function TableRowAction({ handleDelete, handleEdit, noEdit }) {
  return (
    <div
      className={classes.TableRowAction}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      {!noEdit && (
        <button type="button" className={classes.button} onClick={handleEdit}>
          <img src={editIcon} alt="Edit" />
        </button>
      )}
      <button className={classes.button} type="button" onClick={handleDelete}>
        <img src={deleteIcon} alt="Delete" />
      </button>
    </div>
  );
}
