import React, { useEffect, useRef } from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function Tab({
  tab,
  currentTab,
  setCurrentTab,
  textLeft,
  setTabsWidths,
}) {
  const tabRef = useRef();

  const isActive = tab === currentTab;

  useEffect(() => {
    if (tabRef.current) {
      setTabsWidths((prev) => ({
        ...prev,
        [tab]: tabRef.current.offsetWidth,
      }));
    }
  }, [setTabsWidths, tab, currentTab]);

  return (
    <button
      className={classNames(classes.Tab, {
        [classes.active]: isActive,
      })}
      onClick={() => setCurrentTab(tab)}
      type="button"
      role="tab"
      ref={tabRef}
      aria-selected={isActive}
    >
      <span
        style={{
          left: textLeft,
        }}
        className={classes.title}
      >
        {tab}
      </span>
    </button>
  );
}
