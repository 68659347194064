import React, { useEffect, useCallback } from 'react';

import classes from './styles.module.scss';

export default function ReplyBox({
  textareaRef,
  user,
  commentText,
  setCommentText,
  createComment,
  setIsSignUpPopupVisible,
}) {
  const adjustTextareaHeight = useCallback(() => {
    const textarea = textareaRef.current;

    textarea.style.height = '1px';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }, [textareaRef]);

  useEffect(() => {
    adjustTextareaHeight();
  }, [adjustTextareaHeight]);

  return (
    <div className={classes.ReplyBox}>
      <textarea
        ref={textareaRef}
        className={classes.textarea}
        placeholder="Add a comment or ask a question..."
        value={commentText}
        onKeyUp={adjustTextareaHeight}
        onChange={(event) => setCommentText(event.target.value)}
      />

      <button
        type="button"
        className={classes.submitButton}
        onClick={() => {
          setCommentText('');

          if (!user) {
            setIsSignUpPopupVisible(true);
            return;
          }

          const textarea = textareaRef.current;
          textarea.style.height = '1px';

          createComment();
        }}
      >
        Post
      </button>
    </div>
  );
}
