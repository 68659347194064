import React from 'react';

import classes from './styles.module.scss';
import videoTimeToHMS from '../../../../helpers/videoTimeToHMS';

export default function ControlsHeader({
  videoRef,
  videoDuration,
  meetingTitle,
}) {
  return (
    <div className={classes.ControlsHeader}>
      <h3 className={classes.title}>{meetingTitle}</h3>
      {videoRef.current && (
        <div className={classes.time}>
          {videoTimeToHMS(videoRef.current.currentTime)} /{' '}
          {videoTimeToHMS(videoDuration || 0)}
        </div>
      )}
    </div>
  );
}
