import { create } from 'zustand';

export const useCreateMeetingStore = create((set) => ({
  prevSectionId: null,
  isSectionAutoScrollDisabled: true,
  hasClickedOnSection: false,

  setPrevSectionId: (prevSectionId) => set({ prevSectionId }),
  setIsSectionAutoScrollDisabled: (isSectionAutoScrollDisabled) =>
    set({ isSectionAutoScrollDisabled }),
  setHasClickedOnSection: (hasClickedOnSection) => set({ hasClickedOnSection }),
}));

export const useUiStore = create((set) => ({
  breadcrumbsOptions: [],
  isSidebarCollapsed: false,

  setBreadcrumbsOptions: (breadcrumbsOptions) => set({ breadcrumbsOptions }),
  toggleSidebar: () =>
    set((state) => ({ isSidebarCollapsed: !state.isSidebarCollapsed })),
}));
