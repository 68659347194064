import React from 'react';

import classes from './styles.module.scss';

export default function Loader() {
  return (
    <div className={classes.Loader}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}
