import React from 'react';

import classes from './styles.module.scss';
import arrow from '../../../assets/images/auth/arrow.svg';
import checkmark from '../../../assets/images/auth/checkmark.svg';

export default function ConfirmButton({
  isDisabled,
  onClick,
  withArrow,
  style,
}) {
  return (
    <button
      type="submit"
      disabled={isDisabled}
      className={classes.ConfirmButton}
      onClick={onClick}
      style={style}
    >
      {withArrow ? (
        <img src={arrow} alt="Arrow" />
      ) : (
        <img src={checkmark} alt="Checkmark" />
      )}
    </button>
  );
}
