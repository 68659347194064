import React from 'react';

import { useMediaQuery } from 'react-responsive';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import infoIcon from '../../assets/images/infoIcon.svg';

import classes from './styles.module.scss';
import adjustTooltipOnZoom from '../../helpers/adjustTooltipOnZoom';

export default function InfoIcon({ text }) {
  const isZoomedOut = useMediaQuery({ query: '(max-width: 1366px)' });

  return (
    <OverlayTrigger
      onToggle={adjustTooltipOnZoom}
      placement={isZoomedOut ? 'left' : 'auto'}
      overlay={<Tooltip>{text}</Tooltip>}
    >
      <div className={classes.InfoIcon}>
        <img src={infoIcon} alt="Info" />
      </div>
    </OverlayTrigger>
  );
}
