import React, { useState } from 'react';

import moment from 'moment';

import ActionsMenu from '../ActionsMenu';
import EditableTitle from '../EditableTitle';
import TableRowAction from '../TableRowAction';

import downloadFolder from '../../../helpers/downloadFolder';
import { ORG_ROLES } from '../../../constants/main';
import folderIcon from '../../../assets/images/my-library/folder.svg';
import viewedIcon from '../../../assets/images/my-library/viewed.svg';

const FolderRow = ({
  folder,
  onClick,
  user,
  onUpdateTitle,
  handleDelete,
  handleShare,
  handleManageUsers,
  handleCopyLink,
  classes,
  showReport,
  isTrashBin,
  handleMoveToTrash,
  handleRestore,
  viewedMeetingCount,
  meetingCount,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const isOrganizationOwner = user?.orgRole?.access === ORG_ROLES.OWNER;

  const isOwnerOrCreator =
    folder.userRole === 'owner' ||
    folder.userRole === 'creator' ||
    isOrganizationOwner;
  const isNotAllowedToDeleteOrShare =
    folder.userRole !== 'owner' &&
    folder.userRole !== 'creator' &&
    folder.Owner.id !== user?.id &&
    !isOrganizationOwner;
  const isNotAllowedToCopyLink = folder.userRole === 'viewer';
  const isNotAllowedToManageUsers = !!folder.parentId;
  const isNotAllowedToDownload = user?.orgRole?.access !== ORG_ROLES.OWNER;
  const lastPublishedDate = moment(folder.updatedAt).format('MMM D, YYYY');
  const isDeleted = folder.deletedAt;
  const allVideosViewed =
    meetingCount > 0 && viewedMeetingCount === meetingCount;
  const hasUnviewedMeetings = meetingCount > viewedMeetingCount;

  return (
    <tr
      key={folder.id}
      onClick={onClick}
      style={{ cursor: isDeleted ? 'auto' : 'pointer' }}
    >
      <td className={classes.title}>
        <div className={classes.innerContainer}>
          <div className={classes.icon}>
            <img src={folderIcon} alt="Folder" />
          </div>
          <div
            style={{
              pointerEvents: isDeleted ? 'none' : 'auto',
            }}
          >
            <div className={classes.titleContainer}>
              <EditableTitle
                handleUpdateTitle={onUpdateTitle}
                isEditable={isOwnerOrCreator}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              >
                {folder.name}
              </EditableTitle>
              {hasUnviewedMeetings && <div className={classes.new}>New</div>}
              {/* {hasUnviewedMeetings && (
              <span className={classes.newVideosAdded}>New video(s) added</span>
            )} */}
            </div>
            <span className={classes.publishedDate}>
              modified {lastPublishedDate}
            </span>
          </div>
        </div>
      </td>
      <td className={classes.owner}>
        {folder.userId === user?.id ? 'Me' : folder.Owner.name}
      </td>
      <td className={classes.publishedDate}>{lastPublishedDate}</td>
      <td className={classes.viewedCount}>
        {viewedMeetingCount} of {meetingCount}{' '}
        {allVideosViewed && (
          <img src={viewedIcon} alt="Viewed" className={classes.viewedMark} />
        )}
      </td>
      <td className={classes.actions}>
        <TableRowAction
          noEdit={isTrashBin}
          handleDelete={isTrashBin ? handleDelete : handleMoveToTrash}
          handleEdit={() => setIsEditing(true)}
        />
      </td>
      <td className={classes.buttons}>
        <ActionsMenu
          handleDelete={handleDelete}
          handleShare={handleShare}
          handleManageUsers={handleManageUsers}
          handleCopyLink={handleCopyLink}
          handleDownload={() => downloadFolder(folder)}
          handleMoveToTrash={handleMoveToTrash}
          handleRestore={handleRestore}
          isNotAllowedToDelete={isNotAllowedToDeleteOrShare}
          isNotAllowedToShare={isNotAllowedToDeleteOrShare}
          isNotAllowedToManageUsers={isNotAllowedToManageUsers}
          isNotAllowedToCopyLink={isNotAllowedToCopyLink}
          isNotAllowedToDownload={isNotAllowedToDownload}
          isNotAllowedToGetViewerReport={false}
          handleGetViewerReport={() => showReport(folder)}
          isTrashBin={isTrashBin}
        />
      </td>
    </tr>
  );
};

export default FolderRow;
