import { useEffect } from 'react';

const isExcludeRef = (excludeRef, event) => {
  if (Array.isArray(excludeRef)) {
    return excludeRef.some((ref) => ref?.current?.contains(event.target));
  }
  return excludeRef && excludeRef?.current?.contains(event.target);
};

export default function useOnClickOutside(ref, handler, excludeRef) {
  useEffect(() => {
    const listener = (event) => {
      if (
        !ref.current ||
        ref.current.contains(event.target) ||
        isExcludeRef(excludeRef, event)
      ) {
        return;
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler, excludeRef]);
}
