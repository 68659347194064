/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef } from 'react';

import classNames from 'classnames';

import InfoIcon from '../InfoIcon';

import classes from './styles.module.scss';

export default function Switch({
  isChecked,
  onClick,
  label,
  width,
  withOnLabel,
  tooltipText,
}) {
  const switchRef = useRef();

  useEffect(() => {
    if (width) {
      switchRef.current.style.setProperty('--width', `${width}px`);
    } else {
      switchRef.current.style.setProperty('--width', '51px');
    }
  }, [width]);

  return (
    <label
      ref={switchRef}
      className={classNames(classes.Switch, {
        [classes.withTooltip]: tooltipText,
      })}
      style={{
        paddingRight: width,
      }}
    >
      {tooltipText && <InfoIcon text={tooltipText} width={300} />}
      {label && <span className={classes.label}>{label}</span>}
      <input type="checkbox" checked={isChecked} onChange={onClick} />
      <span className={classes.slider} style={{ width }} />
      {withOnLabel && (
        <span
          className={classNames(classes.onLabel, {
            [classes.hidden]: !isChecked,
          })}
        >
          ON
        </span>
      )}
    </label>
  );
}
