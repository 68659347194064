import React from 'react';

import ControlsHeader from './ControlsHeader';
import Progress from './Progress';
import Buttons from './Buttons';

import classes from './styles.module.scss';

export default function Controls({
  videoRef,
  videoDuration,
  isDraggingProgressBar,
  progressBarRef,
  mouseDownHandler,
  isVideoReady,
  isAudio,
  stopVideoPlayer,
  pauseVideoPlayer,
  startVideoPlayer,
  startTimer,
  isRecording,
  createSectionMode,
  setAudioImage,
  isPlaying,
  arePlayerButtonsVisible,
  videoSectionPlayerRef,
  animateProgressBar,
  videoProgress,
  animationId,
  meetingTitle,
}) {
  return (
    <div className={classes.Controls}>
      <ControlsHeader
        videoRef={videoRef}
        videoDuration={videoDuration}
        meetingTitle={meetingTitle}
      />
      <Progress
        isDraggingProgressBar={isDraggingProgressBar}
        progressBarRef={progressBarRef}
        mouseDownHandler={mouseDownHandler}
        videoSectionPlayerRef={videoSectionPlayerRef}
        videoProgress={videoProgress}
        animateProgressBar={animateProgressBar}
        animationId={animationId}
      />
      <Buttons
        isVideoReady={isVideoReady}
        isAudio={isAudio}
        stopVideoPlayer={stopVideoPlayer}
        pauseVideoPlayer={pauseVideoPlayer}
        startVideoPlayer={startVideoPlayer}
        startTimer={startTimer}
        isRecording={isRecording}
        createSectionMode={createSectionMode}
        setAudioImage={setAudioImage}
        isPlaying={isPlaying}
        arePlayerButtonsVisible={arePlayerButtonsVisible}
        videoRef={videoRef}
        animateProgressBar={animateProgressBar}
      />
    </div>
  );
}
