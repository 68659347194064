/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function UnderlinedInput({
  value,
  label,
  errors,
  isTouched,
  isPassword,
  doPasswordsMatch,
  type,
  ...props
}) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <div
      className={classNames(classes.UnderlinedInput, {
        [classes.password]: type === 'password',
        [classes.email]: type === 'email',
      })}
      onClick={() => {
        if (type === 'password') {
          setIsPasswordVisible((prevState) => !prevState);
        }
      }}
    >
      <input
        className={classNames(
          value && classes.active,
          errors && isTouched && classes.error
        )}
        value={value}
        type={isPasswordVisible ? 'text' : type}
        {...props}
        onClick={(event) => {
          event.stopPropagation();
        }}
      />
      <label>{label}</label>
      {isPassword && (
        <div
          className={classes.passwordStrength}
          style={{
            opacity: (isTouched || !doPasswordsMatch) && '100%',
          }}
        >
          {!doPasswordsMatch ? (
            <span className={classes.error}>Passwords do not match</span>
          ) : (
            ['1', 'A', '&', '8+'].map((item) => {
              let isError;
              let isSuccess;

              switch (item) {
                case '1':
                  isError =
                    value.length && !/[0-9]/.test(value) && classes.error;
                  isSuccess =
                    value.length && /[0-9]/.test(value) && classes.success;
                  break;

                case 'A':
                  isError =
                    value.length && !/[A-Z]/.test(value) && classes.error;
                  isSuccess =
                    value.length && /[A-Z]/.test(value) && classes.success;
                  break;

                case '&':
                  isError =
                    value.length && !/[^\w]/.test(value) && classes.error;
                  isSuccess =
                    value.length && /[^\w]/.test(value) && classes.success;
                  break;

                case '8+':
                  isError = value.length && value.length < 8 && classes.error;
                  isSuccess =
                    value.length && value.length >= 8 && classes.success;
                  break;

                default:
                  break;
              }

              return (
                <span id={item} className={classNames(isError, isSuccess)}>
                  {item}
                </span>
              );
            })
          )}
        </div>
      )}
    </div>
  );
}
