import React from 'react';

// This SVG is broken and cannot be rendered correctly as img src
// Works only as JSX
export default function SvgArrow() {
  return (
    <svg
      width="85"
      height="85"
      viewBox="0 0 85 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_89_1124)">
        <circle cx="42.2059" cy="38.2059" r="30.2059" fill="white" />
      </g>
      <path
        d="M40.0463 38.2054L50.1202 28.1316L47.2425 25.2539L34.291 38.2054L47.2425 51.157L50.1202 48.2793L40.0463 38.2054Z"
        fill="#666666"
      />
      <defs>
        <filter
          id="filter0_d_89_1124"
          x="0"
          y="0"
          width="84.4121"
          height="84.4121"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_89_1124"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_89_1124"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
