import React from 'react';

import { Link, useNavigate } from 'react-router-dom';
import cn from 'classnames';

import backIcon from '../../assets/images/my-meetings/back.svg';

import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import classes from './styles.module.scss';

export default function Breadcrumbs({
  breadcrumbsOptions,
  className,
  showBackButton,
}) {
  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen();

  let content = breadcrumbsOptions?.map((option) => (
    <Link key={option.label} to={option.to} className={classes.link}>
      {option.label}
    </Link>
  ));

  if (isMobile) {
    const prevOption = breadcrumbsOptions[breadcrumbsOptions.length - 2];
    const currentOption = breadcrumbsOptions[breadcrumbsOptions.length - 1];

    content = (
      <Link
        to={prevOption?.to}
        className={cn(classes.link, prevOption && classes.linkWithPrev)}
      >
        {currentOption?.label}
      </Link>
    );
  }

  if (showBackButton) {
    content = (
      <button
        onClick={() => navigate(-1)}
        type="button"
        className={classes.backButton}
      >
        <img src={backIcon} alt="Back" />
        <span>Go back</span>
      </button>
    );
  }

  return (
    <div
      className={cn(classes.Breadcrumbs, className, {
        [classes.hasOptions]: breadcrumbsOptions?.length > 1,
      })}
    >
      {content}
    </div>
  );
}
