import React from 'react';

import moment from 'moment';

import FilledButton from '../../Buttons/FilledButton';

import downloadIcon from '../../../assets/images/reports/download.svg';
import closeIcon from '../../../assets/images/closeIcon.svg';
import classes from './styles.module.scss';
// import { getContentTypeIcon } from './helpers';

export default function ReportHeader({
  reportTitle,
  //  contentType,
  contentTitle,
  // folderOwner,
  exportReportToPdf,
  isExport,
  isExporting,
  handleClose,
}) {
  return (
    <header className={classes.ReportHeader}>
      {handleClose && (
        <button
          className={classes.closeButton}
          type="button"
          onClick={handleClose}
        >
          <img src={closeIcon} alt="Close" />
        </button>
      )}
      <div className={classes.container}>
        <h2 className={classes.title}>{reportTitle}</h2>
      </div>

      <div className={classes.reportInfo}>
        <div className={classes.infoContainer}>
          <div className={classes.info}>
            {/*  <img src={getContentTypeIcon(contentType)} alt="" /> */}
            <span className={classes.title}>Title:</span> {contentTitle}
          </div>
          {/* {folderOwner && (
            <div className={classes.contentTitle}>
              Folder Owner: {folderOwner}
            </div>
          )} */}
          <div className={classes.info}>
            <span className={classes.title}>Report date:</span>{' '}
            {moment().format('MMMM DD YYYY')}
          </div>
        </div>
        {!isExport && (
          <div className={classes.buttonContainer}>
            <FilledButton
              onClick={exportReportToPdf}
              isDisabled={isExporting}
              width={196}
            >
              Export{' '}
              <img
                src={downloadIcon}
                alt="Export"
                className={classes.exportIcon}
              />
            </FilledButton>
          </div>
        )}
      </div>
    </header>
  );
}
