import React, { useState } from 'react';

import { useQuery, useMutation } from 'react-query';

import Library from '../../components/Library';
import FolderService from '../../services/FolderService';
import MeetingService from '../../services/MeetingService';
import useFetchingState from '../../components/Library/useFetchingState';

export default function LibraryPage() {
  const [isProcessing, setIsProcessing] = useState(false);

  const {
    page,
    setPage,
    searchInput,
    setSearchInput,
    sortField,
    setSortField,
    sortOrder,
    setSortOrder,
    debouncedSearchInput,
    navigate,
    meetingId,
    isShare,
    id,
    isMobile,
    itemsRange,
  } = useFetchingState();

  const {
    data: folders,
    refetch: refetchFolders,
    isFetching: areFoldersFetching,
  } = useQuery(
    ['folders', page, debouncedSearchInput, sortField, sortOrder],
    () =>
      FolderService.getFolders({
        page,
        search: debouncedSearchInput,
        limit: itemsRange,
        sortField,
        sortOrder,
      }),
    {
      keepPreviousData: true,
      enabled: !id,
    }
  );

  const {
    data: openedFolder,
    refetch: refetchFolder,
    isFetching: isFolderFetching,
  } = useQuery(
    ['folders', id, debouncedSearchInput, sortField, sortOrder],
    () =>
      FolderService.getFolder({
        id,
        search: debouncedSearchInput,
        sortField: sortField === 'name' ? 'title' : sortField,
        sortOrder,
      }),
    {
      keepPreviousData: true,
      enabled: !!id,
      retry: false,
      onError: (error) => {
        if (error?.response?.status === 403) {
          navigate(
            `/request-folder-access/${id}${
              meetingId ? `?meetingId=${meetingId}` : ''
            }`,
            { replace: true }
          );
        }
      },
      onSuccess: (data) => {
        if (
          data?.meetings?.some((meeting) => meeting.status === 'in progress')
        ) {
          setIsProcessing(true);
        } else {
          setIsProcessing(false);
        }
      },
      refetchInterval: isProcessing ? 10000 : false,
    }
  );

  const { mutate: toggleFolderTrashStatus } = useMutation(
    FolderService.toggleFolderTrashStatus,
    {
      onSuccess: () => {
        if (openedFolder) {
          refetchFolder();
        } else {
          refetchFolders();
        }
      },
    }
  );

  const { mutate: toggleMeetingTrashStatusMutation } = useMutation(
    MeetingService.toggleMeetingTrashStatus,
    {
      onSuccess: () => {
        refetchFolder();
      },
    }
  );

  const { mutate: updateFolderMutation } = useMutation(
    FolderService.updateFolder,
    {
      onSuccess: () => {
        if (openedFolder) {
          refetchFolder();
        } else {
          refetchFolders();
        }
      },
    }
  );

  return (
    <Library
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      isMobile={isMobile}
      page={page}
      setPage={setPage}
      sortField={sortField}
      setSortField={setSortField}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      debouncedSearchInput={debouncedSearchInput}
      refetchFolders={refetchFolders}
      refetchFolder={refetchFolder}
      areFoldersFetching={areFoldersFetching}
      isFolderFetching={isFolderFetching}
      folders={folders}
      openedFolder={openedFolder}
      id={id}
      isShare={isShare}
      itemsRange={itemsRange}
      moveFolderToTrashMutation={toggleFolderTrashStatus}
      restoreFolderMutation={toggleFolderTrashStatus}
      moveToTrashMeetingMutation={toggleMeetingTrashStatusMutation}
      restoreMeetingMutation={toggleMeetingTrashStatusMutation}
      updateFolderMutation={updateFolderMutation}
      breadcrumbLabel="My Library"
      breadcrumbTo="/my-library"
    />
  );
}
