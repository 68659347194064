import React, { useEffect, useRef } from 'react';

import { NavLink, Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';

import classes from './styles.module.scss';
import myLibraryIcon from '../../../assets/images/nav/my-library.svg';
import myVideosIcon from '../../../assets/images/nav/my-videos.svg';
import settingsIcon from '../../../assets/images/nav/profile.svg';
import trashIcon from '../../../assets/images/nav/trash-bin.svg';
import myLibraryActiveIcon from '../../../assets/images/nav/my-library-active.svg';
import trashActiveIcon from '../../../assets/images/nav/trash-bin-active.svg';
import profileActiveIcon from '../../../assets/images/nav/profile-active.svg';
import myVideosActiveIcon from '../../../assets/images/nav/my-videos-active.svg';

import logo from '../../../assets/images/snapsop2.png';
import collapse from '../../../assets/images/nav/collapse.svg';
import { useUiStore } from '../../../store';

const links = [
  {
    title: 'My library',
    path: '/my-library',
    icon: myLibraryIcon,
    activeIcon: myLibraryActiveIcon,
  },
  {
    title: 'My Videos',
    path: '/my-videos',
    icon: myVideosIcon,
    activeIcon: myVideosActiveIcon,
  },
  {
    title: 'Settings',
    path: '/profile',
    icon: settingsIcon,
    activeIcon: profileActiveIcon,
  },
  {
    title: 'Trash',
    path: '/trash-bin',
    icon: trashIcon,
    activeIcon: trashActiveIcon,
  },
];

export default function Sidebar() {
  const location = useLocation();
  const currentPath = location.pathname;

  const wasToggledAutomatically = useRef(false);

  const isSidebarCollapsed = useUiStore((state) => state.isSidebarCollapsed);
  const toggleSidebar = useUiStore((state) => state.toggleSidebar);

  const isNarrowDesktop = useMediaQuery({ maxWidth: 1600 });

  useEffect(() => {
    if (
      isNarrowDesktop &&
      !isSidebarCollapsed &&
      !wasToggledAutomatically.current
    ) {
      toggleSidebar();
      wasToggledAutomatically.current = true;
    }
  }, [isNarrowDesktop, isSidebarCollapsed, toggleSidebar]);

  return (
    <nav
      className={classNames(classes.Sidebar, {
        [classes.collapsed]: isSidebarCollapsed,
      })}
    >
      <div className={classes.logoContainer}>
        <Link to="/">
          <img src={logo} alt="Logo" className={classes.logo} />
        </Link>
      </div>
      <ul className={classes.list}>
        {links.map(({ title, path, icon, activeIcon }) => {
          const isActiveLink = currentPath.startsWith(path);
          return (
            <NavLink
              key={path}
              to={path}
              className={({ isActive }) => (isActive ? classes.active : '')}
            >
              <li>
                <div className={classes.iconContainer}>
                  <img src={isActiveLink ? activeIcon : icon} alt={title} />
                </div>
                <span className={classes.title}>{title}</span>
              </li>
            </NavLink>
          );
        })}
      </ul>
      <div className={classes.collapseButtonContainer}>
        <button
          className={classes.collapseButton}
          onClick={toggleSidebar}
          type="button"
        >
          <img src={collapse} alt="Collapse" />
        </button>
      </div>
    </nav>
  );
}
