import React, { useContext, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useFormik } from 'formik';
import * as yup from 'yup';

import logoMobile from '../../assets/images/snapsop2.png';

import { SocketContext } from '../../context';
import { UiContext } from '../../context/UiContext';
import { login } from '../../helpers/globalAuth';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import SplitTemplate from '../../components/SplitTemplate';
import UnderlinedInput from '../../components/Inputs/UnderlinedInput';
import ConfirmButton from '../../components/Buttons/ConfirmButton';
import FilledButton from '../../components/Buttons/FilledButton';
import classes from './styles.module.scss';

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email')
    .required('This field is required'),
  password: yup.string().required('This field is required'),
});

export default function SignInPage() {
  const navigate = useNavigate();
  const { notifyError, redirectAfterLogin, setRedirectAfterLogin } =
    useContext(UiContext);
  const { socket } = useContext(SocketContext);
  const isMobile = useCheckMobileScreen();

  const signIn = async (values) => {
    try {
      await login(values.email, values.password, socket);

      navigate(redirectAfterLogin);

      if (redirectAfterLogin) {
        setRedirectAfterLogin('');
      }
    } catch (error) {
      console.log(error);
      notifyError(error.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: signIn,
  });

  useEffect(() => {
    const shouldRedirectToDefaultPage = ['/my-videos', '/my-library'].includes(
      redirectAfterLogin
    );

    if (!redirectAfterLogin || shouldRedirectToDefaultPage) {
      setRedirectAfterLogin(isMobile ? '/my-videos' : '/my-library');
    }
  }, [isMobile, redirectAfterLogin, setRedirectAfterLogin]);

  const isRedirectToMeeting =
    /\/my-videos\/[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/.test(
      redirectAfterLogin
    );

  const hasError = useMemo(
    () => Object.values(formik.errors).some((error) => error),
    [formik.errors]
  );
  const hasEmptyValue = useMemo(
    () => Object.values(formik.values).some((value) => !value.length),
    [formik.values]
  );

  const isSubmitDisabled = hasError || hasEmptyValue;

  const forgotPasswordLabel = useMemo(() => {
    if (isMobile) {
      return <Link to="/forgot-password">Forgot Password?</Link>;
    }

    return (
      <span>
        Forgot your password? <br />
        <Link to="/forgot-password">Click here</Link> to recover it
      </span>
    );
  }, [isMobile]);

  const submitButton = useMemo(() => {
    if (isMobile) {
      return (
        <FilledButton isDisabled={isSubmitDisabled} type="submit">
          Log In
        </FilledButton>
      );
    }

    return <ConfirmButton isDisabled={isSubmitDisabled} withArrow />;
  }, [isMobile, isSubmitDisabled]);

  return (
    <div className={classes.SignInPage}>
      <SplitTemplate>
        <div className={classes.content}>
          {/* <span
            className={classes.signUp}
            style={{
              visibility: 'hidden',
            }}
          >
            <Link to="/sign-up">Sign up</Link>
          </span> */}
          <div className={classes.logoContainer}>
            <img className={classes.logo} src={logoMobile} alt="SnapSOP" />
          </div>
          <h1>
            {isRedirectToMeeting ? 'Sign in to view your video' : 'Sign in'}
          </h1>
          <form onSubmit={formik.handleSubmit}>
            <UnderlinedInput
              label={
                formik.errors.email && formik.touched.email
                  ? formik.errors.email
                  : 'Your email'
              }
              value={formik.values.email}
              errors={formik.errors.email}
              isTouched={formik.touched.email}
              type="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            <UnderlinedInput
              label={
                formik.errors.password && formik.touched.password
                  ? formik.errors.password
                  : 'Your password'
              }
              value={formik.values.password}
              errors={formik.errors.password}
              isTouched={formik.touched.password}
              type="password"
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            <div className={classes.row}>
              <span className={classes.forgotPassword}>
                {forgotPasswordLabel}
              </span>
              {submitButton}
            </div>
          </form>
        </div>
      </SplitTemplate>
    </div>
  );
}
