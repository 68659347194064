/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useRef, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import folderIcon from '../../../assets/images/my-library/folder.svg';
import subfolderIcon from '../../../assets/images/my-library/subfolder.svg';
import videoIcon from '../../../assets/images/my-library/video.svg';

import { UiContext } from '../../../context/UiContext';
import FolderService from '../../../services/FolderService';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import FilledButton from '../../Buttons/FilledButton';
import Checkbox from '../../Checkbox';
import InfoIcon from '../../InfoIcon';
import classes from './styles.module.scss';
import { ORG_ROLES } from '../../../constants/main';

const FILE_NAMES = {
  DEPARTMENT_FOLDER: 'Parent Folder',
  SUBFOLDER: 'Subfolder',
  SOP_VIDEO: 'SOP Video',
};

const CreateFolderMenu = ({
  setIsVisible,
  refetchFolders,
  refetchFolder,
  addButtonRef,
  folder,
  user,
}) => {
  const [currentOption, setCurrentOption] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isMeOwner, setIsMeOwner] = useState(false);

  const navigate = useNavigate();
  const { notifyError } = useContext(UiContext);
  const { id: parentId } = useParams();

  const menuRef = useRef(null);

  useOnClickOutside(menuRef, () => setIsVisible(false), addButtonRef);

  const isUserManager =
    user.orgRole?.access === ORG_ROLES.MANAGER ||
    user.orgRole?.access === ORG_ROLES.OWNER;
  const isUserFolderViewer = folder?.userRole === 'viewer';

  const options = [
    {
      label: 'Parent Folder',
      onClick: () => setCurrentOption(FILE_NAMES.DEPARTMENT_FOLDER),
      disabled: folder || !isUserManager,
      icon: <img src={folderIcon} alt="Parent Folder" />,
    },
    {
      label: 'Subfolder',
      onClick: () => setCurrentOption(FILE_NAMES.SUBFOLDER),
      disabled: !folder || isUserFolderViewer,
      icon: <img src={subfolderIcon} alt="Subfolder" />,
    },
    {
      label: 'Video',
      onClick: () => {
        navigate(`/create-video?folderId=${folder.id}`);
      },
      disabled: !folder || isUserFolderViewer,
      icon: <img src={videoIcon} alt="Video" />,
    },
  ];

  const createFolder = async () => {
    try {
      await FolderService.createFolder({ name, email, parentId });

      if (parentId) {
        refetchFolder();
      } else {
        refetchFolders();
      }

      setIsVisible(false);
    } catch (error) {
      console.log(error);
      notifyError(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (isMeOwner) {
      setEmail('');
    }
  }, [isMeOwner]);

  const isSubmitDepartmentDisabled =
    !name.trim().length || (!isMeOwner && !email.trim().length);

  let content = (
    <div className={classes.optionsList}>
      <ul>
        {options.map((option) => (
          <li key={option.label}>
            <button
              onClick={() => option.onClick()}
              disabled={option.disabled}
              type="button"
            >
              {option.label} {option.icon}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );

  if (currentOption === FILE_NAMES.DEPARTMENT_FOLDER) {
    content = (
      <div className={classes.departmentFolder}>
        <h5>Create parent folder</h5>

        <form>
          <label>
            <span>Parent Folder Name</span>
            <input
              value={name}
              onChange={(event) => setName(event.target.value)}
              type="text"
            />
          </label>

          <div className={classes.assignFolderContainer}>
            <label>
              <span className={classes.assignFolder}>
                Assign folder owner
                <InfoIcon
                  text={`Folder owners can add, edit
                    and delete files and folders,
                    and manage user access`}
                  width={340}
                />
              </span>
              <input
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                disabled={isMeOwner}
                type="text"
                placeholder="type in email to notify user"
              />
            </label>

            <span className={classes.or}>- OR -</span>

            <Checkbox
              isChecked={isMeOwner}
              onClick={() => setIsMeOwner((prevState) => !prevState)}
              label="I am the folder owner"
            />
          </div>
          <div className={classes.buttonContainer}>
            <FilledButton
              width={160}
              onClick={createFolder}
              type="button"
              isDisabled={isSubmitDepartmentDisabled}
            >
              Create
            </FilledButton>
          </div>
        </form>
      </div>
    );
  }

  if (currentOption === FILE_NAMES.SUBFOLDER) {
    content = (
      <div className={classes.subfolder}>
        <h5>Create subfolder</h5>
        {/* <p>
          Subfolders help you organize SOP videos further (e.g. role, process
          category, etc) so your team knows exactly where to find valuable
          procedures.
        </p> */}

        <label className={classes.nameContainer}>
          <span>Subfolder Name</span>
          <input
            value={name}
            onChange={(event) => setName(event.target.value)}
            type="text"
          />
        </label>
        <div className={classes.buttonContainer}>
          <FilledButton
            onClick={createFolder}
            type="button"
            isDisabled={!name.trim().length}
          >
            Create
          </FilledButton>
        </div>
      </div>
    );
  }

  return (
    <div ref={menuRef} className={classes.CreateFolderMenu}>
      {content}
    </div>
  );
};

export default CreateFolderMenu;
