import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function ViewStatus({ status }) {
  if (!status) {
    return null;
  }

  const isViewed = status === 'Viewed';

  return (
    <div
      className={classNames(classes.ViewStatus, {
        [classes.viewed]: isViewed,
      })}
    >
      {status}
    </div>
  );
}
