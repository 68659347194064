import React, { useEffect, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';
import userIcon from '../../assets/images/usericon.webp';
// import logo from '../../assets/images/snapsop2.png';
import profileIcon from '../../assets/images/profile.svg';
import logoutIcon from '../../assets/images/logout.svg';
import deleteIcon from '../../assets/images/delete.svg';

import { STATIC_URL } from '../../constants/main';
import { hasAuthData } from '../../helpers/authStorage';
import { logout } from '../../helpers/globalAuth';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import UserService from '../../services/UserService';
import classes from './styles.module.scss';
import SearchBar from '../SearchBar';

export default function Header({
  hasUserBlock,
  hasSearch,
  searchInput,
  setSearchInput,
  searchDisabled,
}) {
  const [isUserMenuVisible, setIsUserMenuVisible] = useState(false);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const isLoggedIn = hasAuthData();

  const userMenuRef = useRef();
  const userPhotoRef = useRef();
  const userBlockRef = useRef();

  useOnClickOutside(
    userMenuRef,
    () => setIsUserMenuVisible(false),
    userBlockRef
  );

  const { data, refetch } = useQuery('me', UserService.getMe, {
    enabled: false,
  });

  useEffect(() => {
    if (isLoggedIn) {
      refetch();
    }
  }, [isLoggedIn, refetch]);

  return (
    <header className={classes.Header}>
      {/* <img src={logo} alt="Logo" className={classes.logo} /> */}
      {hasSearch && (
        <div className={classes.searchBarContainer}>
          <SearchBar
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            disabled={searchDisabled}
          />
        </div>
      )}
      {hasUserBlock && (
        <div
          ref={userBlockRef}
          className={classes.userBlock}
          onClick={() => {
            if (!isLoggedIn) {
              return;
            }

            setIsUserMenuVisible((prevState) => !prevState);
          }}
        >
          <span className={classes.username}>{data?.name}</span>
          <div className={classes.userMenuContainer}>
            <ul
              className={classNames(classes.userMenu, {
                [classes.isVisible]: isUserMenuVisible,
              })}
              ref={userMenuRef}
            >
              <li className={classes.profileButton}>
                <button
                  type="button"
                  onClick={() => {
                    setIsUserMenuVisible(false);
                    navigate('/profile');
                  }}
                >
                  <img src={profileIcon} alt="Profile" />
                  Settings
                </button>
              </li>
              <li className={classes.profileButton}>
                <button
                  type="button"
                  onClick={() => {
                    setIsUserMenuVisible(false);
                    navigate('/trash-bin');
                  }}
                >
                  <img src={deleteIcon} alt="Trash" />
                  Trash Bin
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => {
                    logout(() => {
                      setIsUserMenuVisible(false);
                      queryClient.removeQueries();
                      navigate('/');
                    });
                  }}
                >
                  <img src={logoutIcon} alt="Logout" />
                  Logout
                </button>
              </li>
            </ul>

            {data?.photoPath ? (
              <div className={classes.userPhoto} ref={userPhotoRef}>
                <img src={STATIC_URL + data.photoPath} alt="" />
              </div>
            ) : (
              <div className={classes.userIcon} ref={userPhotoRef}>
                <img src={userIcon} alt="" />
              </div>
            )}
          </div>
          <svg
            width="13"
            height="8"
            viewBox="0 0 13 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.36302 4.95L11.313 0L12.727 1.414L6.36302 7.778L-0.000976562 1.414L1.41302 0L6.36302 4.95Z"
              fill="#262B43"
              fillOpacity="0.9"
            />
          </svg>
        </div>
      )}
      <div
        className={classNames(classes.backdrop, {
          [classes.isVisible]: isUserMenuVisible,
        })}
      />
    </header>
  );
}
