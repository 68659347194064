import axios from 'axios';

const FolderService = {
  async createFolder({ name, email, parentId }) {
    const response = await axios.post('/folders', {
      name,
      email,
      parentId,
    });
    return response.data;
  },
  async getFolders({
    page,
    search,
    limit,
    sortField,
    sortOrder,
    deleted = false,
  }) {
    const response = await axios.get('/folders', {
      params: { page, search, limit, sortField, sortOrder, deleted },
    });
    return response.data;
  },

  /* async getDeletedFolders({
    page,
    search,
    limit,
    sortField,
    sortOrder,
    deleted = false,
  }) {
    const response = await axios.get('/folders/deleted', {
      params: { page, search, limit, sortField, sortOrder, deleted },
    });
    return response.data;
  }, */

  async getDeletedFolders({ page, search, limit, sortField, sortOrder }) {
    const response = await axios.get('/folders', {
      params: { page, search, limit, sortField, sortOrder, deleted: true },
    });
    return response.data;
  },

  async getFolder({ id, search, sortField, sortOrder }) {
    const response = await axios.get(`/folders/${id}`, {
      params: { search, sortField, sortOrder },
    });
    return response.data;
  },
  async updateFolder({ folderId, name }) {
    const response = await axios.patch(`/folders/${folderId}`, {
      name,
    });
    return response.data;
  },
  async deleteFolder(id) {
    const response = await axios.delete(`/folders/${id}`);
    return response.data;
  },

  async toggleFolderTrashStatus(id) {
    const response = await axios.post(`/folders/${id}/trash`);
    return response.data;
  },

  async shareFolder({ folderId, userRole, emails, message, meetingId }) {
    const response = await axios.post(`/folders/${folderId}/share`, {
      userRole,
      emails,
      message,
      meetingId,
    });
    return response.data;
  },
  async getFolderUsers(id) {
    const response = await axios.get(`/folders/${id}/users`);
    return response.data;
  },
  async updateFolderUser({ folderId, userId, userRole }) {
    const response = await axios.patch(`/folders/${folderId}/users/${userId}`, {
      userRole,
    });
    return response.data;
  },
  async deleteFolderUser({ folderId, userId }) {
    const response = await axios.delete(`/folders/${folderId}/users/${userId}`);
    return response.data;
  },
  async requestFolderAccess({ folderId, email, message, meetingId }) {
    const response = await axios.post(`/folders/${folderId}/request-access`, {
      email,
      message,
      meetingId,
    });
    return response.data;
  },

  async getDeletedContent({ page, search, limit, sortField, sortOrder }) {
    const response = await axios.get('/folders/trash', {
      params: { page, search, limit, sortField, sortOrder },
    });
    return response.data;
  },
};

export default FolderService;
