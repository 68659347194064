import React, { useState } from 'react';

import ViewReportTh from '../../ViewReportTh';
import ViewStatus from './ViewStatus';

import classes from './styles.module.scss';
import { sortReport } from './helpers';

const ths = [
  { title: 'First Name', field: 'firstName' },
  { title: 'Last Name', field: 'lastName' },
  { title: 'Videos Viewed', field: 'viewedCount' },
  { title: 'Videos Outstanding', field: 'notViewedCount' },
];

const thsSingleVideoReport = [
  { title: 'First Name', field: 'firstName' },
  { title: 'Last Name', field: 'lastName' },
  { title: 'Status', field: 'status' },
];

export default function UsersTable({ report, isExport, isSingleVideoReport }) {
  const [sortField, setSortField] = useState('firstName');
  const [sortOrder, setSortOrder] = useState('asc');

  const sortedReport = sortReport(report, sortField, sortOrder);

  const currentThs = isSingleVideoReport ? thsSingleVideoReport : ths;

  console.log('isSingleVideoReport', isSingleVideoReport);

  return (
    <table className={classes.UsersTable} id="users-report-table">
      <thead>
        <tr>
          {currentThs.map((th) => (
            <ViewReportTh
              key={th.field}
              title={th.title}
              field={th.field}
              sortField={sortField}
              sortOrder={sortOrder}
              setSortField={setSortField}
              setSortOrder={setSortOrder}
              isExport={isExport}
            />
          ))}
        </tr>
      </thead>
      <tbody>
        {sortedReport?.map((user) => {
          return (
            <tr key={user.userReportId}>
              {currentThs.map((th) => {
                if (th.field === 'viewedCount') {
                  return (
                    <td key={th.field}>
                      {user.viewedCount} out of {user.totalCount}
                    </td>
                  );
                }

                if (th.field === 'status') {
                  return (
                    <td key={th.field}>
                      <ViewStatus status={user.status} />
                    </td>
                  );
                }

                return <td key={th.field}>{user[th.field]}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
