import React, { useState } from 'react';

import ActionsMenu from '../ActionsMenu';
import EditableTitle from '../EditableTitle';

import folderIcon from '../../../assets/images/nav/my-library-active.svg';
import classes from './styles.module.scss';

const Subfolder = ({
  title,
  onClick,
  isTitleEditable,
  onUpdateTitle,
  actionsMenuProps,
  hasActionsMenu,
  lastPublishedDate,
  icon = folderIcon,
  hasUnviewedMeetings,
  style,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <section onClick={onClick} className={classes.Subfolder} style={style}>
      <div className={classes.titleContainer}>
        <div className={classes.icon}>
          <img src={icon} alt="Folder" />
        </div>
        <div>
          <EditableTitle
            handleUpdateTitle={onUpdateTitle}
            isEditable={isTitleEditable}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          >
            {title}
          </EditableTitle>
          {hasUnviewedMeetings && (
            <span className={classes.newVideosAdded}>New video(s) added</span>
          )}
          {lastPublishedDate && (
            <span className={classes.publishedDate}>
              modified {lastPublishedDate}
            </span>
          )}
        </div>
      </div>
      <div className={classes.actionsContainer}>
        {hasActionsMenu && <ActionsMenu {...actionsMenuProps} />}
      </div>
    </section>
  );
};

export default Subfolder;
