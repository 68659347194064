/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useContext, useRef, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient, useMutation } from 'react-query';

import * as yup from 'yup';
import axios from 'axios';
import { useFormik } from 'formik';

import Layout from '../../containers/Layout';

import userIcon from '../../assets/images/usericon.webp';
import { UiContext } from '../../context/UiContext';
import { logout } from '../../helpers/globalAuth';
import UserService from '../../services/UserService';
import { STATIC_URL } from '../../constants/main';
import RoundedInput from '../../components/Inputs/RoundedInput';
import ConfirmButton from '../../components/Buttons/ConfirmButton';
import FilledButton from '../../components/Buttons/FilledButton';
import ConfirmActionModal from '../../modals/ConfirmActionModal';
import classes from './styles.module.scss';

const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .required('This field is required')
    .matches(/^\S+$/, 'This field cannot contain white space'),
  lastName: yup
    .string()
    .trim()
    .required('This field is required')
    .matches(/^\S+$/, 'This field cannot contain white space'),
  title: yup.string().trim().required('This field is required'),
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email')
    .required('This field is required'),
  password: yup
    .string()
    .min(8, 'The password should have at minimum length of 8'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .when('password', {
      is: (password) => password?.length > 0,
      then: yup.string().required('Passwords must match'),
    }),
});

export default function ProfilePage() {
  const [image, setImage] = useState(null);
  const [
    isConfirmAccountRemovalModalVisible,
    setIsConfirmAccountRemovalModalVisible,
  ] = useState(false);

  const navigate = useNavigate();
  const { data } = useQuery('me', UserService.getMe);
  const queryClient = useQueryClient();

  const { notifyError, notifySuccess } = useContext(UiContext);

  const fileInputRef = useRef();

  const userInfoMutation = useMutation(UserService.updateMe, {
    onSuccess: (user) => {
      queryClient.setQueryData('me', user);
      notifySuccess('Saved successfully');
    },
    onError: (error) => {
      notifyError(error?.response?.data?.message);
    },
  });

  const { mutate: userPhotoUploadMutate } = useMutation(
    UserService.uploadPhoto,
    {
      onSuccess: (user) => {
        queryClient.setQueryData('me', user);
      },
      onError: (error) => {
        notifyError(error?.response?.data?.message);
      },
    }
  );

  const { mutate: userPhotoDeleteMutate } = useMutation(
    UserService.deletePhoto,
    {
      onSuccess: (user) => {
        queryClient.setQueryData('me', user);
      },
      onError: (error) => {
        notifyError(error?.response?.data?.message);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      firstName: data?.name?.split(' ')[0],
      lastName: data?.name?.split(' ')[1],
      email: data?.email || '',
      title: data?.title || '',
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: ({ firstName, lastName, email, password, confirmPassword }) => {
      userInfoMutation.mutate({
        name: `${firstName} ${lastName}`,
        email,
        password,
        confirmPassword,
        title: formik.values.title,
      });
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (image) {
      userPhotoUploadMutate(image);
    }
  }, [image, userPhotoUploadMutate]);

  const deleteUser = async () => {
    try {
      await axios.delete('/users/me');
      logout(() => {
        queryClient.removeQueries();
        navigate('/sign-in');
      });
    } catch (error) {
      console.log(error);
    }
  };

  const hasError = useMemo(
    () => Object.values(formik.errors).some((error) => error),
    [formik.errors]
  );

  return (
    <Layout>
      <div className={classes.ProfilePage}>
        <div className={classes.container}>
          <div className={classes.main}>
            <div className={classes.fakeBreadCrumb}>Profile Settings</div>
            <div className={classes.account}>
              <div className={classes.userPhoto}>
                <input
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(event) => setImage(event.target.files[0])}
                  ref={fileInputRef}
                  accept="image/*"
                />
                <img
                  src={data?.photoPath ? STATIC_URL + data.photoPath : userIcon}
                  alt="User"
                />
                <ul>
                  <li>
                    <FilledButton
                      width={220}
                      onClick={() => fileInputRef.current.click()}
                    >
                      Upload Image
                    </FilledButton>
                  </li>

                  <li>
                    <button
                      type="button"
                      disabled={!data?.photoPath}
                      className={classes.removeButton}
                      onClick={userPhotoDeleteMutate}
                    >
                      Remove Image
                    </button>
                  </li>
                </ul>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <h1>Profile Details</h1>
                <div className={classes.row}>
                  <RoundedInput
                    label={
                      formik.errors.firstName && formik.touched.firstName
                        ? formik.errors.firstName
                        : 'First name'
                    }
                    value={formik.values.firstName}
                    errors={formik.errors.firstName}
                    isTouched={formik.touched.firstName}
                    type="text"
                    name="firstName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />

                  <RoundedInput
                    label={
                      formik.errors.lastName && formik.touched.lastName
                        ? formik.errors.lastName
                        : 'Last name'
                    }
                    value={formik.values.lastName}
                    errors={formik.errors.lastName}
                    isTouched={formik.touched.lastName}
                    type="text"
                    name="lastName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>

                <RoundedInput
                  label="Organization"
                  value={data?.Organization?.name}
                  type="text"
                  readOnly
                />

                <RoundedInput
                  label="Permissions Level"
                  value={data?.orgRole?.name}
                  type="text"
                  readOnly
                />

                <RoundedInput
                  label="Company Role"
                  value={formik.values.title}
                  type="text"
                  name="title"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errors={formik.errors.title}
                />

                <RoundedInput
                  label={
                    formik.errors.email && formik.touched.email
                      ? formik.errors.email
                      : 'Your email'
                  }
                  value={formik.values.email}
                  errors={formik.errors.email}
                  isTouched={formik.touched.email}
                  type="email"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />

                <RoundedInput
                  label={
                    formik.errors.password && formik.touched.password
                      ? formik.errors.password
                      : 'Change password'
                  }
                  value={formik.values.password}
                  errors={formik.errors.password}
                  isTouched={formik.touched.password}
                  autoComplete="new-password"
                  type="password"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />

                <div style={{ position: 'relative' }}>
                  <RoundedInput
                    label={
                      formik.errors.confirmPassword &&
                      formik.touched.confirmPassword
                        ? formik.errors.confirmPassword
                        : 'Confirm password'
                    }
                    value={formik.values.confirmPassword}
                    errors={formik.errors.confirmPassword}
                    isTouched={formik.touched.confirmPassword}
                    type="password"
                    name="confirmPassword"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.values.password && (
                    <span className={classes.passwordHint}>
                      Click submit button below to successfully change password
                    </span>
                  )}
                </div>
                <div className={classes.confirmButtonContainer}>
                  <ConfirmButton isDisabled={hasError} />
                </div>
              </form>
            </div>
          </div>
        </div>

        <ConfirmActionModal
          show={isConfirmAccountRemovalModalVisible}
          handleClose={() => setIsConfirmAccountRemovalModalVisible(false)}
          onConfirm={deleteUser}
          title="Are you sure you want to delete your account?"
          message="You will not be able to recover any created or assigned meetings once deleted."
        />
      </div>
    </Layout>
  );
}
