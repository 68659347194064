import React from 'react';

import Header from '../../components/Header';
import Sidebar from './Sidebar';

import classes from './styles.module.scss';

export default function Layout({
  children,
  hasUserBlock = true,
  hasSearch,
  searchInput,
  setSearchInput,
  mainStyle,
  style,
}) {
  return (
    <div className={classes.Layout} style={style}>
      <Sidebar />
      <div className={classes.container}>
        <Header
          hasUserBlock={hasUserBlock}
          hasSearch={hasSearch}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
        />
        <main style={mainStyle}>{children}</main>
      </div>
    </div>
  );
}
