import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';

import classNames from 'classnames';
import Modal from 'react-bootstrap/Modal';

import FilledButton from '../../components/Buttons/FilledButton';

import usericon from '../../assets/images/usericon.webp';
import { STATIC_URL } from '../../constants/main';
import classes from './styles.module.scss';
import AnswerService from '../../services/AnswerService';
import Option from '../../components/Option';
import Checkbox from '../../components/Checkbox';
import EmojiPickerWithButton from '../../components/EmojiPickerWithButton';
import UserService from '../../services/UserService';

export default function QuestionModal({
  show,
  handleClose,
  question,
  refetchMeeting,
  preview,
}) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [answerText, setAnswerText] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);

  const { data: user } = useQuery('me', UserService.getMe);

  const { mutate: createAnswer } = useMutation(AnswerService.createAnswer, {
    onSuccess: () => {
      refetchMeeting();
      setIsSubmitted(true);
      setTimeout(() => {
        handleClose();
      }, 5000);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    if (!show) {
      setTimeout(() => {
        setIsSubmitted(false);
        setAnswerText('');
        setSelectedOptions([]);
      }, 150);
    }
  }, [show]);

  const isSubmitDisabled = !answerText.trim().length && !selectedOptions.length;

  const submitAnswer = () => {
    if (!isSubmitDisabled) {
      createAnswer({
        questionId: question.id,
        text: answerText || selectedOptions.join(', '),
      });
    }
  };

  const handleSelectMultipleChoice = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions((currentOptions) =>
        currentOptions.filter((currentOption) => currentOption !== option)
      );
    } else {
      setSelectedOptions((currentOptions) => [...currentOptions, option]);
    }
  };

  let answer = (
    <div className={classes.form}>
      <div className={classes.userpic}>
        <img
          src={user?.photoPath ? STATIC_URL + user?.photoPath : usericon}
          alt="User"
        />
        <p className={classes.username}>{user?.name}</p>
      </div>
      <textarea
        value={answerText}
        onChange={(event) => setAnswerText(event.target.value)}
        placeholder="Start typing..."
      />
      <EmojiPickerWithButton
        setCommentText={setAnswerText}
        buttonBottom="24px"
        buttonRight="24px"
        pickerTop="52px"
      />
    </div>
  );

  if (question.options?.type === 'Single Choice') {
    answer = (
      <ul className={classes.radioButtons}>
        {question.options?.options.map((option) => (
          <Option
            key={option}
            isChecked={selectedOptions.includes(option)}
            onClick={() => setSelectedOptions([option])}
          >
            {option}
          </Option>
        ))}
      </ul>
    );
  }

  if (question.options?.type === 'Multiple Choice') {
    answer = (
      <ul className={classes.multipleChoice}>
        {question.options?.options.map((option) => (
          <Checkbox
            key={option}
            isChecked={selectedOptions.includes(option)}
            onClick={() => handleSelectMultipleChoice(option)}
            label={option}
            big
            labelStyle={{ fontSize: 24 }}
          />
        ))}
      </ul>
    );
  }

  const isAnswered = !!question?.Answers?.find(
    (answr) => answr.User.id === user?.id
  );

  const isPossibleToClose =
    (question?.isAnswerRequired && isAnswered) ||
    preview ||
    !question?.isAnswerRequired;

  let buttons = (
    <div
      className={classNames(
        classes.buttons,
        question?.options && classes.multipleChoice
      )}
    >
      <FilledButton
        type="button"
        className={classes.submitButton}
        onClick={submitAnswer}
        isDisabled={isSubmitDisabled}
        width={192}
      >
        Submit
      </FilledButton>
      {(isAnswered || !question.isAnswerRequired) && (
        <FilledButton
          type="button"
          className={classes.skipButton}
          onClick={handleClose}
          color="black"
          width={133}
        >
          Skip
        </FilledButton>
      )}
    </div>
  );

  if (question?.isAnswerRequired) {
    buttons = (
      <>
        <div
          className={classNames(
            classes.buttons,
            question?.options && classes.multipleChoice
          )}
        >
          <FilledButton
            type="button"
            className={classes.submitButton}
            onClick={submitAnswer}
            disabled={isSubmitDisabled}
          >
            Submit
          </FilledButton>
          {(isAnswered || !question.isAnswerRequired) && (
            <FilledButton
              type="button"
              className={classes.skipButton}
              onClick={handleClose}
              color="black"
            >
              Skip
            </FilledButton>
          )}
        </div>
        <p className={classes.requiredInfo}>
          *This question requires an answer in order to move on through the
          recorded video.
        </p>
      </>
    );
  }

  if (question?.options) {
    buttons = (
      <>
        <div
          className={classNames(
            classes.buttons,
            classes.multipleChoiceButtonsContainer
          )}
        >
          <FilledButton
            type="button"
            className={classes.submitButton}
            onClick={submitAnswer}
            isDisabled={isSubmitDisabled}
            width={192}
          >
            Submit
          </FilledButton>
          {(isAnswered || !question.isAnswerRequired) && (
            <FilledButton
              type="button"
              className={classes.skipButton}
              onClick={handleClose}
              width={133}
              color="black"
            >
              Skip
            </FilledButton>
          )}
        </div>
        {question.isAnswerRequired && (
          <p className={classes.requiredInfo}>
            *This question requires an answer in order to move on through the
            recorded video.
          </p>
        )}
      </>
    );
  }

  if (preview && question?.options) {
    buttons = (
      <div className={classNames(classes.buttons, classes.multipleChoice)}>
        <FilledButton
          type="button"
          className={classes.submitButton}
          onClick={submitAnswer}
          isDisabled
          width={192}
        >
          Submit
        </FilledButton>

        <FilledButton
          type="button"
          className={classes.skipButton}
          onClick={handleClose}
          color="black"
        >
          Skip
        </FilledButton>

        <div className={classes.messageContainer}>
          <p>You are in preview mode</p>
          <p>Click Skip to finishing reviewing your video</p>
        </div>
      </div>
    );
  }

  if (preview && !question?.options) {
    buttons = (
      <div className={classNames(classes.buttons)}>
        <div className={classes.messageContainer}>
          <p>You are in preview mode</p>
          <p>Click Skip to finishing reviewing your video</p>
        </div>
        <FilledButton
          type="button"
          className={classes.submitButton}
          onClick={submitAnswer}
          isDisabled
          width={192}
        >
          Submit
        </FilledButton>

        <FilledButton
          type="button"
          className={classes.skipButton}
          onClick={handleClose}
          color="black"
        >
          Skip
        </FilledButton>
      </div>
    );
  }

  return (
    <Modal
      show={show}
      centered
      contentClassName={classNames(classes.QuestionModal, {
        [classes.submitted]: isSubmitted,
        [classes.multipleChoice]: !!question?.options,
      })}
      dialogClassName={classNames(classes.dialog, {
        [classes.submitted]: isSubmitted,
        [classes.multipleChoice]: !!question?.options,
      })}
      backdropClassName={classes.backdrop}
    >
      {(isPossibleToClose || isSubmitted) && (
        <i className={classes.closeIcon} onClick={handleClose}>
          Close
        </i>
      )}
      {isSubmitted ? (
        <div className={classes.submitMessage}>
          <p className={classes.title}>Response Submitted!</p>
          <p>
            Your response has been saved below under the Question Responses tab
          </p>
        </div>
      ) : (
        <>
          <p
            className={classes.question}
            //   style={{ textAlign: question?.options ? 'left' : 'center' }}
          >
            {question?.text}
          </p>
          {answer}
          {buttons}
        </>
      )}
    </Modal>
  );
}
