import React, { useState } from 'react';

import Tab from './Tab';

import classes from './styles.module.scss';

export default function ReportTabs({ tabs, currentTab, setCurrentTab }) {
  const [tabsWidths, setTabsWidths] = useState({});

  const currentTabIndex = tabs.findIndex((tab) => tab === currentTab);
  let lineLeft = 0;

  if (currentTabIndex > 0) {
    lineLeft = Object.keys(tabsWidths)
      .slice(0, currentTabIndex)
      .reduce((acc, tab) => acc + tabsWidths[tab] + 44, 0);
  }

  return (
    <div className={classes.ReportTabs}>
      {tabs.map((tab, index) => (
        <Tab
          key={tab}
          tab={tab}
          index={index}
          tabCount={tabs.length}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          lastOne={index === 0}
          setTabsWidths={setTabsWidths}
        />
      ))}
      <div
        className={classes.line}
        style={{
          width: tabsWidths[currentTab],
          left: lineLeft,
        }}
      />
    </div>
  );
}
