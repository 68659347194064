import React from 'react';

import Modal from 'react-bootstrap/Modal';
import { useQuery } from 'react-query';
import { Link, useLocation } from 'react-router-dom';

import moment from 'moment-timezone';

import closeIcon from '../../assets/images/closeIcon.svg';

import localTimeZone from '../../helpers/localTimeZone';
import formatDuration from '../../helpers/formatDuration';
import FolderService from '../../services/FolderService';
import classes from './styles.module.scss';

const timezoneOffset = moment.tz(Date.now(), localTimeZone).format('Z');

export default function MeetingDetailsModal({ show, handleClose, meeting }) {
  const { pathname } = useLocation();

  const isLibrary = pathname.startsWith('/my-library');

  const { data: folder } = useQuery(
    ['folder', meeting?.folderId],
    () => FolderService.getFolder({ id: meeting?.folderId }),
    {
      enabled: !!meeting?.folderId && !isLibrary,
    }
  );

  if (!meeting) {
    return null;
  }

  const isMeetingExpired =
    meeting.expiresAt && new Date(meeting.expiresAt) < new Date();

  let sharedDate = '-';
  let expirationInfo;

  if (isMeetingExpired) {
    expirationInfo = 'Video expired';
  } else if (!meeting.expiresAt) {
    expirationInfo = '-';
  } else {
    expirationInfo = `${moment(meeting.expiresAt).format(
      'M-D-YY h:mm A'
    )} (UTC${timezoneOffset}) ${localTimeZone.split('/')[1]}`;
  }

  if (meeting.plannedToShareAt) {
    sharedDate = moment(meeting.plannedToShareAt).format('M-D-YY');
  }

  if (meeting.sharedAt) {
    sharedDate = moment(meeting.sharedAt).format('M-D-YY');
  }

  const duration = formatDuration(meeting.duration);

  const isFileLocationVisible = !isLibrary;

  const isNotAllowedToCheckVersions = meeting.status === 'in progress';

  return (
    <Modal
      show={show}
      centered
      contentClassName={classes.MeetingDetailsModal}
      dialogClassName={classes.dialog}
      backdropClassName={classes.backdrop}
    >
      <div className={classes.header}>
        <h1 className={classes.title}>Details</h1>
        <i className={classes.closeIcon} onClick={handleClose}>
          <img src={closeIcon} alt="Close" />
        </i>
      </div>
      <div className={classes.content}>
        <p>
          <span className={classes.label}>Title:</span> {meeting.title}
        </p>
        <p>
          <span className={classes.label}>Owner:</span>{' '}
          <div className={classes.owner}>
            {meeting.ownerName || meeting.Owner.name}
            <span className={classes.ownerTitle}>
              {meeting.ownerTitle || meeting.Owner.title}
            </span>
          </div>
        </p>
        {sharedDate !== '-' && (
          <p>
            <span className={classes.label}>Published:</span> {sharedDate}
          </p>
        )}
        {expirationInfo !== '-' && (
          <p>
            <span className={classes.label}>Expiration:</span> {expirationInfo}
          </p>
        )}
        {meeting.version && (
          <p>
            <span className={classes.label}>Version:</span>{' '}
            {isNotAllowedToCheckVersions ? (
              `${meeting.version}`
            ) : (
              <Link
                to={`/my-videos/${meeting.id}/history`}
                onClick={handleClose}
              >
                {meeting.version}
              </Link>
            )}
          </p>
        )}
        {meeting.duration && (
          <p>
            <span className={classes.label}>Duration:</span> {duration}
          </p>
        )}
        {isFileLocationVisible && (
          <p>
            <span className={classes.label}>File Location:</span>{' '}
            <Link to={`/my-library/${meeting.folderId}`}>
              {folder?.data.name}
            </Link>
          </p>
        )}
        {!!meeting?.MeetingReviews?.length && (
          <p>
            <>
              <span className={classes.label}>Approved by:</span>
              <br />
              {meeting.MeetingReviews.map((review) => (
                <>
                  {review.User
                    ? `${review.User.name}, ${review.User.title}`
                    : review.email}
                  <br />
                </>
              ))}
            </>
          </p>
        )}
      </div>
    </Modal>
  );
}
