/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, useEffect } from 'react';

import { useQuery } from 'react-query';
import classNames from 'classnames';

import PlaceholderSection from './PlaceholderSection';
import Section from './Section';
import SectionGap from './SectionGap';
import SvgArrow from './SectionMenu/SvgArrow';
import DeleteSectionIcon from './DeleteSectionIcon';

import classes from './styles.module.scss';
import UserService from '../../services/UserService';
import { useCreateMeetingStore, useUiStore } from '../../store';

// const SECTION_WIDTH = 336;
const SECTION_WIDTH = 235;

const CreateMeetingFooter = React.memo(
  ({
    sections,
    setVideoPlayerSource,
    setCurrentSectionId,
    currentSectionId,
    setIsConfirmSectionRemovalVisible,
    startVideo,
    currentSectionsPage,
    setCurrentSectionsPage,
    isCameraEnabled,
    isScreenEnabled,
    isRecording,
    firstPlaceholderTitle,
    showPlaceholder,
    showQuestionForm,
    setIsQuestionFormVisible,
    setFetchedQuestionData,
    isQuestionFormVisible,
    showAssignSectionForm,
    isAssignSectionFormVisible,
    meeting,
    meetingTitle,
    setIsNoTitleWarningVisible,
    stopVideoPlayer,
    hideQuestionAndAssignSectionForms,
    hidePlaceholder,
    startAudio,
    isAudioEnabled,
    setIsScreenRecordReminderVisible,
    sectionTitle,
    setSectionTitle,
    createMeeting,
    setMeetingId,
    refetchSections,
    folderId,
    setFile,
    createDefaultSectionName,
    setIsSectionSavedModalVisible,
    setIsSectionCreationStopped,
    processingProgress,
    changeSectionPosition,
    updateSectionTitle,
    createSection,
    updateTextSection,
    setIsNotAllowedToDeleteSectionMessageVisible,
  }) => {
    const [sectionsPageCount, setSectionPageCount] = useState(0);
    const [
      activePlaceholderPrevSectionIds,
      setActivePlaceholderPrevSectionIds,
    ] = useState([]);
    const [sectionsContainerWidth, setSectionsContainerWidth] = useState(0);

    const isSidebarCollapsed = useUiStore((state) => state.isSidebarCollapsed);

    const zoom = getComputedStyle(document.documentElement)
      .getPropertyValue('--zoom')
      .trim();
    const zoomedSectionWidth = SECTION_WIDTH * zoom;

    useEffect(() => {
      const calculateSectionsContainerWidth = () => {
        const sidebarWidth = isSidebarCollapsed ? 126 * zoom : 373 * zoom;

        const width = window.innerWidth - sidebarWidth - 20 - 60;
        setSectionsContainerWidth(width);
      };

      calculateSectionsContainerWidth();

      window.addEventListener('resize', calculateSectionsContainerWidth);

      return () => {
        window.removeEventListener('resize', calculateSectionsContainerWidth);
      };
    }, [isSidebarCollapsed, zoom]);

    const sectionsListRef = useRef();
    const innerSectionsListRef = useRef();
    const footerRef = useRef();
    const leftSwitchRef = useRef();
    const sectionTitleInputRef = useRef();
    const sectionsListContainerRef = useRef();

    // const { width } = useResizeObserver(sectionsListContainerRef);

    const { data: user } = useQuery('me', UserService.getMe);

    const sectionsCount =
      Math.floor(sectionsContainerWidth / (zoomedSectionWidth + 40)) || 1;

    const isSectionAutoScrollDisabled = useCreateMeetingStore(
      (state) => state.isSectionAutoScrollDisabled
    );
    const setIsSectionAutoScrollDisabled = useCreateMeetingStore(
      (state) => state.setIsSectionAutoScrollDisabled
    );

    const scrollSections = (direction) => {
      if (direction === 'right') {
        if (currentSectionsPage === 0) {
          return;
        }
        setCurrentSectionsPage((prevPage) => prevPage - 1);
      } else {
        if (sectionsPageCount === currentSectionsPage) {
          return;
        }
        setCurrentSectionsPage((prevPage) => prevPage + 1);
      }
    };

    const handleSectionDrop = (event) => {
      if (isRecording || !event.dataTransfer.getData('sectionIndex')) {
        return;
      }

      const coords = innerSectionsListRef.current.getBoundingClientRect();
      const xPosition = event.clientX - coords.left;
      const sectionIndex = event.dataTransfer.getData('sectionIndex');
      let newIndex = Math.max(
        Math.floor(xPosition / (zoomedSectionWidth + 40)),
        0
      );

      // Prevent changing position of sections which were not crated by user
      if (meeting.userRole !== 'creator') {
        const otherSections = sections.filter(
          (section) => section.userId !== user?.id
        );

        if (newIndex < otherSections.length) {
          newIndex = otherSections.length;
        }
      }
      changeSectionPosition(sectionIndex, newIndex);
    };

    useEffect(() => {
      if (firstPlaceholderTitle && !sections) {
        const defaultTitle = createDefaultSectionName({
          placeholderType: firstPlaceholderTitle,
        });

        setSectionTitle(defaultTitle);
        sectionTitleInputRef.current?.focus();
      }
    }, [
      createDefaultSectionName,
      firstPlaceholderTitle,
      sections,
      setSectionTitle,
    ]);

    useEffect(() => {
      setSectionPageCount(
        Math.max(
          sections?.length +
            1 +
            activePlaceholderPrevSectionIds.length -
            sectionsCount,
          0
        )
      );
    }, [activePlaceholderPrevSectionIds.length, sections, sectionsCount]);

    // Scroll to the last section when a new section is added and auto-scroll is enabled
    useEffect(() => {
      if (isSectionAutoScrollDisabled) {
        return;
      }

      setTimeout(() => {
        setCurrentSectionsPage(sectionsPageCount);
        setIsSectionAutoScrollDisabled(true);
      }, 0);
    }, [
      sectionsPageCount,
      setCurrentSectionsPage,
      isSectionAutoScrollDisabled,
      setIsSectionAutoScrollDisabled,
    ]);

    /* useEffect(() => {
      const positionTitle = () => {
        const { left } = leftSwitchRef.current.getBoundingClientRect();
        setTitlePosition(left + 30);
      };

      window.addEventListener('resize', positionTitle);
      positionTitle();

      return () => {
        window.removeEventListener('resize', positionTitle);
      };
    }, [sections, sectionsCount]); */

    return (
      <footer
        className={classes.CreateMeetingFooter}
        onDrop={handleSectionDrop}
        onDragOver={(event) => event.preventDefault()}
        ref={footerRef}
      >
        <div className={classes.footerContainer}>
          <div
            className={classes.sectionsListContainer}
            ref={sectionsListContainerRef}
          >
            <div
              className={classNames(classes.switchLeft, classes.switch)}
              onClick={() => scrollSections('right')}
              ref={leftSwitchRef}
            >
              <SvgArrow />
            </div>
            <div
              className={classNames(classes.switchRight, classes.switch)}
              onClick={() => scrollSections('left')}
            >
              <SvgArrow />
            </div>
            <ul
              className={classes.sectionsList}
              ref={sectionsListRef}
              style={{ width: (SECTION_WIDTH + 40) * sectionsCount }}
            >
              <div
                ref={innerSectionsListRef}
                className={classes.inner}
                style={{
                  left:
                    // currentSectionsPage * -sectionsListRef.current?.offsetWidth,
                    currentSectionsPage * -(SECTION_WIDTH + 40),
                }}
              >
                {firstPlaceholderTitle && !sections && meeting && (
                  <li
                    className={classNames(classes.section, classes.placeholder)}
                  >
                    <DeleteSectionIcon
                      className={classes.deleteIcon}
                      onClick={(event) => {
                        event.stopPropagation();
                        hideQuestionAndAssignSectionForms();
                        hidePlaceholder();
                        try {
                          stopVideoPlayer();
                          // eslint-disable-next-line no-empty
                        } catch (error) {}
                      }}
                    />
                    <span>Section Placeholder</span>
                    <div className={classes.title}>
                      <input
                        ref={sectionTitleInputRef}
                        type="text"
                        value={sectionTitle}
                        onChange={(event) =>
                          setSectionTitle(event.target.value)
                        }
                      />
                    </div>
                  </li>
                )}
                {sections?.map((section, index) => {
                  return (
                    <>
                      <Section
                        updateTextSection={updateTextSection}
                        createSection={createSection}
                        updateSectionTitle={updateSectionTitle}
                        refetchSections={refetchSections}
                        isActive={currentSectionId === section.id}
                        user={user}
                        setIsQuestionFormVisible={setIsQuestionFormVisible}
                        classes={classes}
                        section={section}
                        index={index}
                        key={section.id}
                        setVideoPlayerSource={setVideoPlayerSource}
                        setCurrentSectionId={setCurrentSectionId}
                        setIsConfirmSectionRemovalVisible={
                          setIsConfirmSectionRemovalVisible
                        }
                        setIsNotAllowedToDeleteSectionMessageVisible={
                          setIsNotAllowedToDeleteSectionMessageVisible
                        }
                        setFetchedQuestionData={setFetchedQuestionData}
                        meeting={meeting}
                        stopVideoPlayer={stopVideoPlayer}
                        hideQuestionAndAssignSectionForms={
                          hideQuestionAndAssignSectionForms
                        }
                        hidePlaceholder={hidePlaceholder}
                        sectionTitle={sectionTitle}
                        setSectionTitle={setSectionTitle}
                        createDefaultSectionName={createDefaultSectionName}
                        setIsSectionSavedModalVisible={
                          setIsSectionSavedModalVisible
                        }
                        setIsSectionCreationStopped={
                          setIsSectionCreationStopped
                        }
                        processingProgress={processingProgress}
                      />
                      <SectionGap
                        //  disabled={isRecording}
                        prevSectionId={section.id}
                        disabled={index === sections.length - 1}
                        updateTextSection={updateTextSection}
                        createSection={createSection}
                        refetchSections={refetchSections}
                        showPlaceholder={showPlaceholder}
                        hidePlaceholder={hidePlaceholder}
                        isRecording={isRecording}
                        isScreenEnabled={isScreenEnabled}
                        isCameraEnabled={isCameraEnabled}
                        isAudioEnabled={isAudioEnabled}
                        onScreen={() => setIsScreenRecordReminderVisible(true)}
                        onCamera={startVideo}
                        onAudio={startAudio}
                        onQuestion={showQuestionForm}
                        onAssignSection={showAssignSectionForm}
                        setIsQuestionFormVisible={setIsQuestionFormVisible}
                        isQuestionFormVisible={isQuestionFormVisible}
                        isAssignSectionFormVisible={isAssignSectionFormVisible}
                        meeting={meeting}
                        meetingTitle={meetingTitle}
                        setIsNoTitleWarningVisible={setIsNoTitleWarningVisible}
                        sections={sections}
                        createMeeting={createMeeting}
                        folderId={folderId}
                        setMeetingId={setMeetingId}
                        setFile={setFile}
                        setSectionTitle={setSectionTitle}
                        createDefaultSectionName={createDefaultSectionName}
                        setActivePlaceholderPrevSectionIds={
                          setActivePlaceholderPrevSectionIds
                        }
                      />
                    </>
                  );
                })}
                <li>
                  <div className={classes.sectionMenuContainer}>
                    <PlaceholderSection
                      updateTextSection={updateTextSection}
                      createSection={createSection}
                      refetchSections={refetchSections}
                      showPlaceholder={showPlaceholder}
                      hidePlaceholder={hidePlaceholder}
                      isRecording={isRecording}
                      isScreenEnabled={isScreenEnabled}
                      isCameraEnabled={isCameraEnabled}
                      isAudioEnabled={isAudioEnabled}
                      onScreen={() => setIsScreenRecordReminderVisible(true)}
                      onCamera={startVideo}
                      onAudio={startAudio}
                      onQuestion={showQuestionForm}
                      onAssignSection={showAssignSectionForm}
                      setIsQuestionFormVisible={setIsQuestionFormVisible}
                      isQuestionFormVisible={isQuestionFormVisible}
                      isAssignSectionFormVisible={isAssignSectionFormVisible}
                      meeting={meeting}
                      meetingTitle={meetingTitle}
                      setIsNoTitleWarningVisible={setIsNoTitleWarningVisible}
                      sections={sections}
                      createMeeting={createMeeting}
                      folderId={folderId}
                      setMeetingId={setMeetingId}
                      setFile={setFile}
                      setSectionTitle={setSectionTitle}
                      createDefaultSectionName={createDefaultSectionName}
                    />
                  </div>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </footer>
    );
  }
);

export default CreateMeetingFooter;
