/* eslint-disable no-param-reassign */
import React, { useEffect, useCallback } from 'react';
import classNames from 'classnames';

import classes from './styles.module.scss';

let animationId;

export default function Progress({
  isDraggingProgressBar,
  progressBarRef,
  mouseDownHandler,
  videoSectionPlayerRef,
  videoProgress,
  animateProgressBar,
}) {
  const progressBarHandler = useCallback(
    (event) => {
      const progressBar = progressBarRef.current;
      const clickPosition =
        event.clientX - progressBar.getBoundingClientRect().left;
      const progressBarWidth = progressBar.offsetWidth;
      const percentage = clickPosition / progressBarWidth;
      const currentTime = percentage * videoSectionPlayerRef.current?.duration;

      videoSectionPlayerRef.current.currentTime = currentTime;

      animateProgressBar();
    },
    [animateProgressBar, progressBarRef, videoSectionPlayerRef]
  );

  const stopAnimation = () => {
    cancelAnimationFrame(animationId);
  };

  useEffect(() => {
    return () => {
      stopAnimation();
    };
  }, [animateProgressBar, videoSectionPlayerRef]);

  return (
    <div
      className={classes.Progress}
      onMouseDown={mouseDownHandler}
      onClick={(event) => {
        progressBarHandler(event);
        event.stopPropagation();
      }}
    >
      <div
        className={classNames(
          classes.progressBar,
          isDraggingProgressBar && classes.dragging
        )}
        ref={progressBarRef}
      >
        <div
          className={classes.progress}
          style={{
            width: `${videoProgress}%`,
          }}
        />
      </div>
    </div>
  );
}
