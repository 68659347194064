import React from 'react';

import logo from '../../assets/images/snapsop-transparent.svg';

import classes from './styles.module.scss';

export default function SplitTemplate({ children }) {
  return (
    <div className={classes.SplitTemplate}>
      <div className={classes.leftSide}>
        <img src={logo} alt="SnapSOP" />
      </div>
      <div className={classes.rightSide}>{children}</div>
    </div>
  );
}
