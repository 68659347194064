/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useMemo, useState } from 'react';

import axios from 'axios';
// import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { UiContext } from '../../context/UiContext';
import SplitTemplate from '../../components/SplitTemplate';
import ConfirmButton from '../../components/Buttons/ConfirmButton';
import classes from './styles.module.scss';
import UnderlinedInput from '../../components/Inputs/UnderlinedInput';

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email')
    .required('This field is required'),
});

export default function ForgotPasswordPage() {
  const [currentView, setCurrentView] = useState('recovery');
  const { notifyError } = useContext(UiContext);

  const resetPassword = async (values) => {
    try {
      await axios.post(`/forgot-password`, {
        email: values.email,
      });

      setCurrentView('email');
    } catch (error) {
      notifyError(error?.response?.data?.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: resetPassword,
  });

  const hasError = useMemo(
    () => Object.values(formik.errors).some((error) => error),
    [formik.errors]
  );
  const hasEmptyValue = useMemo(
    () => Object.values(formik.values).some((value) => !value.length),
    [formik.values]
  );

  const isSubmitDisabled = hasError || hasEmptyValue;

  return (
    <div className={classes.ForgotPasswordPage}>
      <SplitTemplate>
        {currentView === 'recovery' && (
          <div className={classes.content}>
            {/* <span className={classes.signIn}>
              <Link to="/sign-in">Sign in</Link>
            </span> */}
            <h1>Recover password</h1>
            <form onSubmit={formik.handleSubmit}>
              <UnderlinedInput
                label={
                  formik.errors.email && formik.touched.email
                    ? formik.errors.email
                    : 'Your email'
                }
                value={formik.values.email}
                errors={formik.errors.email}
                isTouched={formik.touched.email}
                type="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <div className={classes.row}>
                <span className={classes.forgotPassword}>
                  We will send a password reset link to your email. <br />{' '}
                  Follow it and set up a new password.
                </span>
              </div>
              <div className={classes.buttonContainer}>
                <ConfirmButton isDisabled={isSubmitDisabled} />
              </div>
            </form>
          </div>
        )}

        {currentView === 'email' && (
          <div className={classes.content}>
            {/* <span className={classes.signIn}>
              <Link to="/sign-in">Sign in</Link>
            </span> */}
            <h1>We’ve just sent you an email</h1>
            <div className={classes.info}>
              <span className={classes.email}>noreply@snapsop.com</span>
              <p>
                Haven&apos;t received an email? Check your spam folder or{' '}
                <span onClick={() => setCurrentView('recovery')}>
                  click here
                </span>{' '}
                to send it again.
              </p>
            </div>
          </div>
        )}
      </SplitTemplate>
    </div>
  );
}
