import React from 'react';

import Loader from './Loader';

import classes from './styles.module.scss';

export default function PublishingOverlay() {
  return (
    <div className={classes.PublishingOverlay}>
      <div className={classes.overlay} />
      <div className={classes.text}>
        <Loader />
        <h2 className={classes.title}>Your File is being processed</h2>
        <p className={classes.info}>
          This will only take a few minutes. We we&apos;ll also send an email
          when your file is ready to share
        </p>
      </div>
    </div>
  );
}
